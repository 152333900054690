<template>
  <v-dialog max-width="900" content-class="dialog-full-height" v-model="isModalOpen" :fullscreen="$vuetify.breakpoint.sm">
      <v-card :loading="isBusy">
        <v-card-title>
          <span class="title">Edit Profile</span>
          <v-spacer></v-spacer>
          <v-icon @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 py-0">
          <v-layout wrap>

            <v-stepper alt-labels v-model="step">
              <v-stepper-header>
                <v-flex v-if="isMobile" xs12 class="d-flex justify-center mt-1" >
                  <span v-if="step === 1">General Information</span>
                  <span v-if="step === 2">Addresses</span>
                  <span v-if="step === 3">Personal Information</span>
                  <span v-if="step === 4">Political Leanings</span>
                </v-flex>
                <v-stepper-step
                  :class="isMobile ? 'pt-1' : ''"
                  :complete="step > 1"
                  step="1"
                >
                  General Information
                </v-stepper-step>

                <v-divider :class="isMobile ? 'mt-4' : ''"></v-divider>

                <v-stepper-step
                  :class="isMobile ? 'pt-1' : ''"
                  :complete="step > 2"
                  step="2"
                >
                  Addresses
                </v-stepper-step>
                <v-divider :class="isMobile ? 'mt-4' : ''"></v-divider>

                <v-stepper-step
                  :class="isMobile ? 'pt-1' : ''"
                  step="3"
                  :complete="step > 3"
                >
                  Personal Information
                  <small>Optional, but helpful</small>
                </v-stepper-step>

                <v-divider  :class="isMobile ? 'mt-4' : ''" v-if="client.uses_political_leaning"></v-divider>

                <v-stepper-step
                  :class="isMobile ? 'pt-1' : ''"
                  step="4"
                  v-if="client.uses_political_leaning"
                >
                  Political Leanings
                  <small>Optional, but very important</small>
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
                <!--General Information-->
                <v-stepper-content step="1" :class="`${isMobile? 'px-0' : ''} pt-2 pl-0 pr-0 pb-0`">
                  <v-form ref="step1" @submit.prevent="onUpdateMember()">
                    <v-card>
                      <v-card-text class="stepper-scroll-content-box">
                        <v-layout wrap>
                          <v-alert type="info" outlined :value="true" v-if="!member.avatar_url && isKP">Hey, let’s save a photo before you go! Also, make sure your profile is accurate.</v-alert>
                          <v-flex col-sm-4 col-md-4 col-12>
                            <v-avatar
                              size="140"
                              class="cursor-pointer bordered_avatar"
                              :style="kpPreferenceBorder(member)"
                            >
                              <img v-if="member.avatar_url" :alt="member.fullname"
                                  :src="getThumbImageMedium(member.avatar_url)"/>
                              <img v-else :alt="member.fullname" :src="'/images/member-avatar.jpg'"/>
                            </v-avatar>
                            <input ref="avatarUploader" type="file" accept="image/*" class="d-none"
                                  @change="onUploadAvatar()">
                          </v-flex>
                          <v-flex col-sm-8 col-md-8 col-12 :class="isMobile ? '' : 'mt-10'">
                            <v-btn large color="primary" class="mr-3" @click="openUploadImagePopup(member)">Upload new photo
                            </v-btn>
                            <!--<p class="mt-4 mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p> //no longer relevant-->
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex v-if="client && client.uses_coordinators && member.regions && member.regions.length > 0"
                                  col-sm-6 col-md-6 col-12>
                            <v-select
                              multiple
                              chips
                              label="Regions this member is coordinator"
                              :items="member.regions"
                              item-text="name"
                              v-model="member.regions"
                              readonly
                              dense
                            >
                            </v-select>
                          </v-flex>
                          <v-flex class="pb-0" col-sm-6 col-md-6 col-12>
                            <v-text-field
                              outlined
                              label="Preferred First Name*"
                              :rules="nickNameRules"
                              :error-messages="errorMessages('nickname')"
                              v-model="member.nickname"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="pb-0" col-sm-6 col-md-6 col-12>
                            <v-text-field
                              outlined
                              label="Last Name*"
                              :rules="lastNameRules"
                              :error-messages="errorMessages('lastname')"
                              v-model="member.lastname"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="py-0" col-sm-12 col-md-12 col-12>
                            <v-text-field
                              outlined
                              prepend-icon="email"
                              label="Email*"
                              :rules="emailRules"
                              :error-messages="errorMessages('email')"
                              v-model="member.email"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="py-0" col-sm-6 col-md-6 col-12>
                            <v-text-field
                              outlined
                              prepend-icon="phone_iphone"
                              label="Cell Phone"
                              v-mask="'(###) ###-####'"
                              :error-messages="errorMessages('cell')"
                              :rules="member.bad_cell ? [] : phoneNumberRules"
                              v-model="member.cell"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="py-0" col-sm-6 col-md-6 col-12>
                            <v-text-field
                              outlined
                              prepend-icon="phone"
                              label="Office Phone"
                              v-mask="'(###) ###-####'"
                              :error-messages="errorMessages('office_phone')"
                              :rules="isBad(member.office_phone) ? [] : phoneNumberRules"
                              v-model="member.office_phone"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="py-0" col-sm-6 col-md-6 col-12 v-if="client && client.uses_dob">
                            <v-date-picker2 dense label="Date of Birth" v-model="member.dob" is-normal-date></v-date-picker2>
                          </v-flex>
                          <v-flex class="py-0" col-sm-6 col-md-6 col-12 v-if="client && client.uses_grad_year">
                            <v-select
                              outlined
                              label="Graduation Year"
                              prepend-icon="fa-light fa-graduation-cap"
                              :items="gradYearList"
                              v-model="member.grad_year"
                              @click:append-outer="onClear('grad_year')"
                              dense
                            >
                            </v-select>
                          </v-flex>
        <!-- start custom fields -->
        <v-flex sm12>
        <span class="text-h5 mr-2">Additional Fields</span>
        <v-icon>
            fa-light fa-hand-point-down
          </v-icon>
        <v-divider class="mb-6 mt-2"></v-divider>
        </v-flex>
                          <v-flex
                              class="py-0"
                              col-sm-6 col-md-6 col-12
                              v-if="totalFields && totalFields.length"
                              v-for="(field, index) in totalFields"
                              :key="`${field.id}_field`"
                          >
                            <div v-if="field.type == 'text' && field.show_member" class="d-flex py-0" >
                              <v-text-field
                                outlined
                                :value="field.pivot ? field.pivot.metadata.value : ''"
                                :label="field.name"
                                @input="(val) => fieldHandler(val, field)"
                                :disabled="!Boolean(field.edit_member)"
                                dense
                              >
                              </v-text-field>
                              <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                            </div>
                            <div v-if="field.type == 'dropdown' && field.show_member" class="custom-selector d-flex py-0">
                              <v-select
                                outlined
                                :value="field.pivot ? field.pivot.metadata : []"
                                @input="(val) => fieldHandler(val, field)"
                                :items="field.metadata"
                                item-text="name"
                                item-value="name"
                                :label="field.name"
                                return-object
                                :disabled="!Boolean(field.edit_member)"
                                dense
                              >
                              </v-select>
                              <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                            </div>
                            <div v-if="field.type == 'checkbox' && field.show_member" class="d-flex py-0">
                              <v-select
                                outlined
                                :value="field.pivot ? field.pivot.metadata : []"
                                @input="(val) => fieldHandler(val, field)"
                                :items="field.metadata"
                                item-text="name"
                                item-value="name"
                                :label="field.name"
                                multiple
                                chips
                                dense
                                return-object
                                :disabled="!Boolean(field.edit_member)"
                              >
                              </v-select>
                              <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                            </div>
                            <div v-if="field.type == 'toggle' && field.show_member" class="d-flex py-0  ">
                              <v-switch
                                color="primary"
                                :label="field.name"
                                :input-value="field.pivot ? field.pivot.metadata.value : false"
                                @change="(val) => fieldHandler(val, field)"
                                :disabled="!Boolean(field.edit_member)"
                              >
                              </v-switch>
                              <v-tooltip bottom v-if="field.hint" max-width="300px" class="ml-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      <span>{{ field.hint }}</span>
                    </v-tooltip>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-end pt-3">
                        <div>
                          <v-btn
                            color="defult"
                            @click="isModalOpen = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="next"
                            class="ml-4"
                          >
                            Next
                            <v-icon right>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>
                
              <!--Start Addresses-->
                <v-stepper-content step="2" :class="`${isMobile? 'px-0' : ''} pt-2 pl-0 pr-0 pb-0`">
                  <v-form ref="step2" @submit.prevent="onUpdateMember()">
                    <v-card>
                      <v-card-text class="stepper-scroll-content-box">
                        <v-alert
                          class="text-center"
                          color="primary"
                          outlined
                          :value="true"
                        >
                          {{ textAlertStep2 }}
                        </v-alert>
                        
                        <v-layout wrap>
                        <v-flex col-sm-12 col-md-12 col-12 class="d-flex align-center flex-wrap py-5">
                          <v-layout row align-center class="w-100">
                            <v-icon>fa-light fa-mailbox-flag-up</v-icon>
                            <v-subheader class="font-weight-bold pl-2">Mailing Address</v-subheader>
                          </v-layout>
                              <span
                                v-if="mailing_address"
                                :class="address_error.mailing_error ? 'red--text' : ''"
                              >You must select an address from the Google options or delete this address</span>
                            <div class="d-flex full-width mt-2">
                              <address-input
                                v-if="mAddressRendered"
                                ref="mailing_address"
                                :class="address_error.mailing_error? 'address-input-error' : ''"
                                placeholder="Enter Mailing Address"
                                v-model="member.mailing_address"
                                @deleteError="address_error.mailing_error = false"
                                @input="onChangeAddress(5)"
                              ></address-input>
                              <div class="address_icon">
                                <v-icon
                                  v-if="isSuccessAddress('mailing_')"
                                  class="pl-3"
                                  size="18"
                                  color="success"
                                >check
                                </v-icon>
                              </div>
                            </div>
                          </v-flex>
                          
                          <v-flex col-sm-12 col-md-12 col-12 class="d-flex align-center flex-wrap py-5">
                          <v-layout row align-center class="w-100">
                            <v-icon>fa-light fa-home-user</v-icon>
                            <v-subheader class="font-weight-bold pl-2">Home Address</v-subheader>
                          </v-layout>
                              <span
                                v-if="home_address"
                                :class="address_error.home_error ? 'red--text' : ''"
                              >You must select an address from the Google options or delete this address</span>
                            <div class="d-flex full-width mt-2">
                              <address-input
                                ref="home_address"
                                :class="address_error.home_error? 'address-input-error' : ''"
                                placeholder="Enter Home Address"
                                v-model="member.home_address"
                                prepend-icon="home"
                                @deleteError="address_error.home_error = false"
                                @input="onChangeAddress(0)"
                              ></address-input>
                              <div class="address_icon">
                                <v-icon
                                  v-if="isSuccessAddress('home_')"
                                  class="pl-3"
                                  size="18"
                                  color="success"
                                >check
                                </v-icon>
                              </div>
                            </div>
                          </v-flex>
                          
                          <v-flex col-sm-12 col-md-12 col-12 class="d-flex align-center flex-wrap py-5">
                          <v-layout row align-center class="w-100">
                            <v-icon>fa-light fa-building</v-icon>
                            <v-subheader class="font-weight-bold pl-2">1st Work Address</v-subheader>
                          </v-layout>
                            <span
                              v-if="work_address"
                              :class="address_error.work_error ? 'red--text' : ''"
                            >You must select an address from the Google options or delete this address</span>
                            <div class="d-flex full-width mt-2">
                              <address-input
                                ref="work_address"
                                :class="address_error.work_error ? 'address-input-error' : ''"
                                placeholder="Enter 1st Work Address"
                                v-model="member.work_address"
                                prepend-icon="business"
                                @deleteError="address_error.work_error = false"
                                @input="onChangeAddress(1)"
                              ></address-input>
                              <div class="address_icon">
                                <v-icon
                                  v-if="isSuccessAddress('work_')"
                                  class="pl-3"
                                  size="18"
                                  color="success"
                                >check</v-icon>
                                <v-tooltip bottom v-if="member.work_address">
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon @click="openDeleteAddressDialog('work')">
                                      <div v-on="on">
                                        <v-icon size="18" color="error">delete</v-icon>
                                      </div>
                                    </v-btn>
                                  </template>
                                  <span>Remove Work address and districts</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-flex>
                        
                          <v-flex col-sm-12 col-md-12 col-12 class="d-flex align-center flex-wrap py-5"
                                  v-if="client && client.uses_2nd_work">
                          <v-layout row align-center class="w-100">
                            <v-icon>fa-light fa-building</v-icon>
                            <v-subheader class="font-weight-bold pl-2">2nd Work Address</v-subheader>
                          </v-layout>
                            <span
                              v-if="work2_address"
                              :class="address_error.work2_error ? 'red--text' : ''"
                            >You must select an address from the Google options or delete this address</span>
                            <div class="d-flex full-width mt-2">
                              <address-input
                                ref="work2_address"
                                :class="address_error.work2_error? 'address-input-error' : ''"
                                placeholder="Enter 2nd Work Address"
                                v-model="member.work2_address"
                                prepend-icon="business"
                                @deleteError="address_error.work2_error = false"
                                @input="onChangeAddress(2)"
                              ></address-input>
                              <div class="address_icon">
                                <v-icon
                                  v-if="isSuccessAddress('work2_')"
                                  class="pl-3"
                                  size="18"
                                  color="success"
                                >check
                                </v-icon>
                                <v-tooltip bottom v-if="member.work2_address">
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon @click="openDeleteAddressDialog('work2')">
                                      <div v-on="on">
                                        <v-icon size="18" color="error">delete</v-icon>
                                      </div>
                                    </v-btn>
                                  </template>
                                  <span>Remove Work2 address and districts</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-flex>
                          
                          <v-flex col-sm-12 col-md-12 col-12 class="d-flex align-center flex-wrap py-5"
                              v-if="client && client.uses_3rd_work"
                          >
                          <v-layout row align-center class="w-100">
                            <v-icon>fa-light fa-building</v-icon>
                            <v-subheader class="font-weight-bold pl-2">3rd Work Address</v-subheader>
                          </v-layout>
                            <span
                              v-if="work3_address"
                              :class="address_error.work3_error ? 'red--text' : ''"
                            >You must select an address from the listed options or delete this address</span>

                            <div class="d-flex full-width mt-2">
                              <address-input
                                ref="work3_address"
                                :class="address_error.work3_error? 'address-input-error' : ''"
                                placeholder="Enter 3rd Work Address"
                                v-model="member.work3_address"
                                prepend-icon="business"
                                @deleteError="address_error.work3_error = false"
                                @input="onChangeAddress(3)"
                              ></address-input>
                              <div class="address_icon">
                                <v-icon
                                  v-if="isSuccessAddress('work3_')"
                                  class="pl-3"
                                  size="18"
                                  color="success"
                                >check
                                </v-icon>
                                <v-tooltip bottom v-if="member.work3_address">
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon @click="openDeleteAddressDialog('work3')">
                                      <div v-on="on">
                                        <v-icon size="18" color="error">delete</v-icon>
                                      </div>
                                    </v-btn>
                                  </template>
                                  <span>Remove Work3 address and districts</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-flex>
                          
                          <v-flex xcol-sm-12 col-md-12 col-12 class="d-flex align-center flex-wrap py-5"
                                  v-if="client && client.uses_4th_work">
                          <v-layout row align-center class="w-100">
                            <v-icon>fa-light fa-building</v-icon>
                            <v-subheader class="font-weight-bold pl-2">4th Work Address</v-subheader>
                          </v-layout>
                            <span
                              v-if="work4_address"
                              :class="address_error.work4_error ? 'red--text' : ''"
                            >You must select an address from the listed options or delete this address</span>
                            <div class="d-flex full-width mt-2">
                              <address-input
                                ref="work4_address"
                                :class="address_error.work4_error ? 'address-input-error' : ''"
                                outlined
                                placeholder="Enter 4th Work Address"
                                v-model="member.work4_address"
                                prepend-icon="business"
                                @deleteError="address_error.work4_error = false"
                                @input="onChangeAddress(4)"
                              ></address-input>
                              <div class="address_icon">
                                <v-icon
                                  v-if="isSuccessAddress('work4_')"
                                  class="pl-3"
                                  size="18"
                                  color="success"
                                >check
                                </v-icon>
                                <v-tooltip bottom v-if="member.work4_address">
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon @click="openDeleteAddressDialog('work4')">
                                      <div v-on="on">
                                        <v-icon size="18" color="error">delete</v-icon>
                                      </div>
                                    </v-btn>
                                  </template>
                                  <span>Remove Work4 address and districts</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-space-between pt-3">
                        <v-btn
                          outlined
                          color="primary"
                          @click="step = 1"
                        >
                        <v-icon left>mdi-chevron-left</v-icon>
                          Back
                        </v-btn>
                        <div>
                          <v-btn
                            color="defult"
                            @click="isModalOpen = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="next"
                            class="ml-4"
                          >
                            Next
                            <v-icon right>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>
                <!-- End address section  -->
                <!--Personal Information-->
                <v-stepper-content step="3" :class="`${isMobile? 'px-0' : ''} pt-2 pl-0 pr-0 pb-0`">
                  <v-form ref="step3" @submit.prevent="onUpdateMember()">
                    <v-card>
                      <v-card-text class="stepper-scroll-content-box">
                        <v-alert
                          class="text-center"
                          color="primary"
                          outlined
                          :value="true"
                        >
                          {{ textAlertStep4 }}
                        </v-alert>
                        <v-layout wrap>
                        
                          <v-flex col-12 class="px-2 py-5">
                          <v-layout row align-center class="w-100">
                            <v-icon color="primary">mdi-school</v-icon>
                            <v-subheader class="font-weight-bold pl-2">Colleges or Universities Attended</v-subheader>
                          </v-layout>
                            <v-autocomplete
                              label="Start typing official school name"
                              color="blue-grey lighten-2 mt-3"
                              class="mt-2"
                              :menu-props="{closeOnContentClick:true}"
                              :value="member.universities"
                              @change="setUniversity"
                              :search-input.sync="searchUniversities"
                              :items="universities"
                              item-text="name"
                              item-value="id"
                              :loading="isBusy"
                              hide-details
                              return-object
                              outlined
                              multiple
                              filled
                              dense
                            >
                              <template v-slot:selection="{item}"></template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                            <div class="mb-2 pt-1">
                              <div class="d-flex flex-wrap">
                                <div v-for="(university, index) of member.universities" class="mr-2" :key="`${index}_university`">
                                  <v-menu class="my-1"
                                          v-model="displaySelected[getIndex(university.id, member.universities)]"
                                         
                                          bottom
                                          left
                                          :close-on-content-click="false"
                                          origin="top left"
                                          content-class="role_card"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-chip
                                        class="my-1"
                                        v-on="on"
                                        close
                                        outlined
                                        color="primary"
                                        :input-value="university.name"
                                        @click:close="removeUniversityFromMember(index)"
                                      >
                                        {{ university.name }}
                                      </v-chip>
                                    </template>
                                  </v-menu>
                                </div>
                              </div>
                            </div>
                          </v-flex>
                          <v-layout row align-center class="w-100">
                            <v-divider></v-divider>
                          </v-layout>

                          <v-flex xs12 sm12 class="py-5" v-if="client.uses_interests">
                            <v-layout row align-center class="w-100">
                            <v-icon color="primary">mdi-hiking</v-icon>
                            <v-subheader class="font-weight-bold pl-2">Hobbies/Interests</v-subheader>
                          </v-layout>
                            <div
                              v-for="interest in hobbiesInterests"
                              :key="`${interest.id}_interest`"
                            >
                              <v-checkbox
                                v-if="interest.parent_id === null"
                                color="primary"
                                :label="interest.name"
                                class="mt-0"
                                :input-value="isEmptyInterest(interest)"
                                @change="editMemberInterests($event, interest)"
                              ></v-checkbox>
                              <div
                                class="ml-4"
                                v-for="childInterest in hobbiesInterests"
                                :key="`${childInterest.id}_childInterest`"
                              >
                                <v-checkbox
                                  v-if="interest.id === childInterest.parent_id
                                  && isEmptyInterest(interest)
                                  && childInterest.type !== 'favorite_team'
                                  && childInterest.type !== 'note_hobby'"
                                  color="primary"
                                  :label="childInterest.name"
                                  class="mt-0"
                                  :input-value="isEmptyInterest(childInterest)"
                                  @change="editMemberInterests($event, childInterest)"
                                ></v-checkbox>
                                <div
                                  v-if="interest.id === childInterest.parent_id
                                && childInterest.type === 'watching_sports'
                                && isEmptyInterest(childInterest)
                                && isEmptyInterest(interest)"
                                >
                                  <favorite-teams-autocomplete
                                    :clientId="client.id"
                                    :memberFavoriteTeams="memberFavoriteTeams"
                                    @response="changeMemberFavoriteTeams"
                                    @remove="removeMemberFavoriteTeam"
                                  ></favorite-teams-autocomplete>
                                </div>
                                <div
                                  v-if="childInterest.type === 'other'
                                        && interest.id === childInterest.parent_id
                                        && isEmptyInterest(interest)
                                        && isEmptyInterest(childInterest)"
                                >
                                  <v-textarea
                                    v-model="otherHobbies[childInterest.id]"
                                    label="Your Hobby"
                                    auto-grow
                                    outlined
                                    rows="1"
                                    row-height="15">
                                  </v-textarea>
                                </div>
                              </div>
                              <div
                                v-if="interest.parent_id === null
                                && interest.type === 'other'
                                && isEmptyInterest(interest)"
                              >
                                <v-textarea
                                  v-model="otherHobbies[interest.id]"
                                  label="Your Hobby"
                                  auto-grow
                                  outlined
                                  rows="1"
                                  row-height="15">
                                </v-textarea>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-space-between pt-3">
                        <v-btn
                          outlined
                          color="primary"
                          @click="step = 2"
                        >
                        <v-icon left>mdi-chevron-left</v-icon>
                          Back
                        </v-btn>
                        <div>
                          <v-btn
                            color="defult"
                            @click="isModalOpen = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            v-if="client.uses_political_leaning"
                            color="primary"
                            @click="next"
                            class="ml-4"
                          >
                            Next
                            <v-icon right>mdi-chevron-right</v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            color="success"
                            @click="onUpdateMember"
                            class="ml-4"
                          >
                            Finish
                            <v-icon right>mdi-check-circle</v-icon>
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>
                <!--Political Leanings-->
                <v-stepper-content
                  step="4"
                  :class="`${isMobile? 'px-0' : ''} pt-2 pl-0 pr-0 pb-0`"
                  v-if="client.uses_political_leaning"
                >
                  <v-form ref="step4" @submit.prevent="onUpdateMember()">
                    <v-card>
                      <v-card-text class="stepper-scroll-content-box">
                        <v-alert
                          class="text-center"
                          color="primary"
                          outlined
                          :value="true"
                        >
                          {{ textAlertStep5 }}
                        </v-alert>
                        <v-row class="mb-4" justify="space-between">
                          <v-col class="text-center">
                              <span
                                class="text-h4 font-weight-bold"
                              >{{ title }}</span>
                          </v-col>
                        </v-row>
                        <v-slider

                          v-model="member.political_leaning_id"
                          :color="color"
                          :track-color="color"
                          always-dirty
                          ticks="always"
                          tick-size="8"
                          min="1"
                          max="5"
                        >
                          <template v-slot:prepend>
                            <v-icon large :color="donkey" class="fa-solid fa-democrat"></v-icon>
                          </template>
                          <template v-slot:append>
                            <v-icon large :color="elephant" class="fa-solid fa-republican"></v-icon>
                          </template>
                        </v-slider>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-space-between pt-3">
                        <v-btn
                          outlined
                          color="primary"
                          @click="step = 3"
                        >
                        <v-icon left>mdi-chevron-left</v-icon>
                          Back
                        </v-btn>
                        <div>
                          <v-btn
                            color="default"
                            @click="isModalOpen = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="onUpdateMember"
                            class="ml-4"
                          >
                            Finish
                            <v-icon right>mdi-check-circle</v-icon>
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            
          </v-layout>
        </v-card-text>
      </v-card>
    <upload-avatar-modal
      ref="UploadAvatar"
      @update="getMember"
    ></upload-avatar-modal>
  </v-dialog>
</template>

<script>
import ClientService from '@/services/ClientService'
import CloudinaryService from '@/services/CloudinaryService'
import DistrictService from '@/services/DistrictService'
import MemberService from '@/services/MemberService'

import AddressInput from '@/components/common/address-input.vue'
import VDatePicker2 from '@/components/common/date-picker'
import EditSilenceModal from '@/components/members/edit-silence-modal'
import FavoriteTeamsAutocomplete from '@/components/auth/favorite-teams-autocomplete'
import UniversityService from "@/services/UniversityService";

import kpPreference from '@/entities/kp-preference'
import _ from "lodash";
import swal from "sweetalert2";
import InterestService from "@/services/InterestService";
import UploadAvatar from "@/components/my-kp/upload-avatar-modal";

export default {
  name: "KpProfileEditModal",
  mixins: [ClientService, CloudinaryService, DistrictService, MemberService, InterestService, UniversityService],
  components: {
    AddressInput,
    VDatePicker2,
    EditSilenceModal,
    "favorite-teams-autocomplete": FavoriteTeamsAutocomplete,
    'upload-avatar-modal': UploadAvatar,
  },
  data() {
    return {
      isModalOpen: false,
      step: 1,
      tab: null,
      activeIndex: 0,
      districtList: [],
      client: {},
      isCellMask: true,
      assignedLegislators: [],
      kpPreference: kpPreference,
      politicalLeanings: [],
      searchInputFull: '',
      politicalLeaningsId: null,
      memberFavoriteTeams: [],
      otherHobbies: {},
      work_address: null,
      work2_address: null,
      work3_address: null,
      work4_address: null,
      home_address: null,
      mailing_address: null,
      mAddressRendered: true,
      address_error: {
        home_error: false,
        work_error: false,
        work2_error: false,
        work3_error: false,
        work4_error: false,
        mailing_error: false,
      },
      errors: [],
      favoriteTeam: [],
      politicalIssues: [],
      hobbiesInterests: [],
      universities: [],
      clientList: [],
      searchUniversities: '',
      displaySelected: {},
      member: {
        custom_fields: [],
        interests: [],
        political_issues: [],
        meta_fields: [],
      },
      error: '',
      isBusy: false,
      clientFields: [],
      tabs: [
        {
          icon: 'account_box',
          title: 'General',
        },
        {
          icon: 'home',
          title: 'Addresses',
        },
        {
          icon: 'fa-solid fa-person-booth',
          title: 'Politics',
        }
      ],
      bpm: 3,
      interval: null,
    }
  },
  computed: {
    isFederalClient(){
      return this.client && this.client.is_use_state === 0
    },
    lastNameRules() {
      return [
        v => !!v || 'Last name is required.',
      ]
    },
    nickNameRules() {
      return [
        v => !!v || 'First name is required.',
      ]
    },
    emailRules() {
      return [
        v => !!v || 'Email address is required.',
        v => this.validateEmail(v) || 'Email address must be valid.',
      ]
    },
    gradYearList() {
      const thisYear = moment().year()
      return _.range(1940, thisYear + 1)
    },
    textAlertStep5() {
      return `The following information is kept private and it is optional; however, it is very important. As a political organization that protects and promotes ${this.client.profession} on your behalf, it is helpful for us to understand our member’s political leanings so that we are better-prepared for the future. Move slider left or right or leave as is if you would like to opt-out.`
    },
    textAlertStep4() {
      return `These are optional yet help us find and form legislative connections as well as learn more about you!`
    },
    textAlertStep3() {
      return `Please provide any personal social media accounts you may wish to share. These are private and optional, yet they help us find and form strong legislative connections.`
    },
    textAlertStep2() {
      return `Please include the most-accurate address for home and office(s). Physical addresses, please, no PO Boxes (these are only used to pinpoint your political district).`
    },
    houseDistrictList() {
      return this.districtList.filter(district => !district.is_federal && /^H[A-Za-z0-9_]+\w*/.test(district.district))
    },
    senDistrictList() {
      return this.districtList.filter(district => !district.is_federal && /^S\d+\w*/.test(district.district))
    },
    conDistrictList() {
      return this.districtList.filter(district => district.is_federal && /^CD\d+\w*/.test(district.district))
    },
    federalSenateList() {
      return this.districtList.filter(district => district.is_federal && /^[^\d]{2}$/.test(district.district))
    },
    totalFields() {
      let result = []

      for (let i = 0; i < this.clientFields.length; i++) {
        let suggest = this.member.custom_fields.find(item => item.id == this.clientFields[i].id)
        if (suggest) {
          result.push(suggest)
        } else {
          result.push(this.clientFields[i])
        }
      }

      return result
    },
    color() {
      let memberLearnings = this.politicalLeanings.find(item => item.id === this.member.political_leaning_id)
      return typeof memberLearnings !== 'undefined' ? memberLearnings.color : '#9c27b0'
    },
    title() {
      let memberLearnings = this.politicalLeanings.find(item => item.id === this.member.political_leaning_id)
      return typeof memberLearnings !== 'undefined' ? memberLearnings.name : 'Independent/Other/Unknown'
    },
    donkey() {
      let memberLearnings = this.politicalLeanings.find(item => item.id === 1)
      let color = typeof memberLearnings !== 'undefined' ? memberLearnings.color : '#1976d2'

      if (this.member.political_leaning_id === 1) return color
      if (this.member.political_leaning_id === 2) return this.shadeColor(color, 50)
      if (this.member.political_leaning_id === 3) return this.color
      if (this.member.political_leaning_id === 4) return this.shadeColor(color, 100)
      return '#E0E0E0'
    },
    elephant() {
      let memberLearnings = this.politicalLeanings.find(item => item.id === 5)
      let color = typeof memberLearnings !== 'undefined' ? memberLearnings.color : '#f44336'
      if (this.member.political_leaning_id === 1) return '#E0E0E0'
      if (this.member.political_leaning_id === 2) return this.shadeColor(color, 100)
      if (this.member.political_leaning_id === 3) return this.color
      if (this.member.political_leaning_id === 4) return this.shadeColor(color, 50)
      return color
    },
  },
  beforeDestroy: function () {
    this.resetEventListeners()
  },
  async mounted() {
    await this.getMember()
    await this.getClient()
    
    //Set Google address field input event listener 
    this.setGeoFieldInputEventListener();

    await this.getInterestsList()
    await this.getUniversity()
    await this.getPoliticalLeaningsList()
    await this.refreshDistrict()
    await this.updateDistricts()
    await this.getClientCustomFields()
    await this.getPoliticalIssuesList()
  },
  methods: {
    getUniversity() {
      const params = {
        'search': this.searchUniversities,
        'limit': 5,
      }
      this.getUniversities(params).then(response => {
        this.universities = response.data.data
      })
    },
    setUniversity(value) {
      this.member.universities = value
    },
    removeUniversityFromMember(value) {
      this.member.universities.splice(value, 1)
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    empty(array, callback) {
      return array && array.length ? callback : '';
    },
    changeMemberFavoriteTeams(value) {
      this.memberFavoriteTeams = value
    },
    removeMemberFavoriteTeam(value) {
      const index = this.memberFavoriteTeams.findIndex(object => object.id === value.id);
      this.memberFavoriteTeams.splice(index, 1)
    },
    shadeColor(color, amount) {
      return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    },
    noteHobby(parent_id = null) {
      const otherInterest = this.hobbiesInterests.find(item => item.parent_id === parent_id && item.type === 'other')
      if (otherInterest) {
        const hobby = this.member.interests.find(item => item.type === 'note_hobby' && otherInterest.id === item.id)
        return hobby ? hobby : '';
      }
      return '';
    },
    inputListener(event, prefix) {
      this.address_error[`${prefix}_error`] = false
      this.member[`${prefix}_address`] = event.target.value
      this.member[`${prefix}_house_district`] = ''
      this.member[`${prefix}_sen_district`] = ''
      this.member[`${prefix}_con_district`] = ''
      this[`${prefix}_address`] = event.target.value

      if(this.isFederalClient) {
        this.member[`${prefix}_house_district_id`] = null
        this.member[`${prefix}_sen_district_id`] = null
        this.member[`${prefix}_con_district_id`] = null
      }
    },
    async getMember() {
      this.member = {...this.userData, ...this.member}
      const data = (await this.getMembers({'id': this.member.id})).data
      this.member = Object.assign(this.member, data)
      const otherInterests = this.member.interests.filter(item => item.type === 'note_hobby')
      otherInterests.forEach(hobby => {
        this.otherHobbies[hobby.parent_id] = hobby.name
      })
      this.memberFavoriteTeams = this.member.interests.filter(item => item.type === 'favorite_team')
      if (this.member.political_leaning_id == null) {
        this.member.political_leaning_id = 3
      }
      this.formatCustomFields()

      //Start Here set member's mailing address
      try {
        const memberUser = (await this.getClientMemberUser(this.userData.client_id, this.userData.id)).data;
        this.mAddressRendered = false;

        if (memberUser && !memberUser.mailing_address) {
          if (this.member && this.member.work_address) {
            this.member.mailing_address = this.member.work_address;
          } else if (this.member && this.member.home_address) {
            this.member.mailing_address = this.member.home_address;
          }
        } else {
          this.member.mailing_address = memberUser.mailing_address;
        }

        await this.isCheckMemberMailingAddress();
        this.mAddressRendered = true;

      } catch (error) {
        console.error('Error fetching member:', error);
      }
      //End Here set member's mailing address

    },
    async formatCustomFields() {
      this.error = ''
      this.member = _.cloneDeep(this.parseCustomFieldMetadata(this.member))

      this.assignedLegislators = (await this.getMemberAssignedLegislators(this.userId)).data.map(legislator =>
        legislator.fullname)
    },
    async refreshDistrict() {
      this.districtList = (await this.getClientDistricts(this.client.id)).data
        .filter(district => {
            if(this.isFederalClient){
             return district; 
            }
            return /^(H|S|CD)[A-Za-z0-9_]+[\w-]*$/.test(district.district)
        })
    },
    async getClient() {
      if (!this.member.client_id) return null
      this.clientList = (await this.getClients()).data
      this.client = this.clientList.find(client => client.id === this.member.client_id)
    },
    openUploadImagePopup(member) {
      this.$refs.UploadAvatar.open(member)
    },
    isEmptyInterest(interest) {
      const memberInterest = this.member.interests.findIndex(item => item.id === interest.id)
      return memberInterest >= 0
    },
    editMemberInterests(event, value) {
      if (event) {
        this.member.interests.push(value)
      } else {
        this.removeInterest(value)
      }
    },
    removeInterest(value) {
      const index = this.member.interests.findIndex(object => object.id === value.id);
      if (index >= 0) {
        const childInterests = this.member.interests.filter(object => object.parent_id === value.id)
        childInterests.forEach(object => this.removeInterest(object))
        this.member.interests.splice(index, 1)
      }
      if (value.type === 'watching_sports') {
        this.memberFavoriteTeams = [];
      }
    },
    openDeleteAddressDialog(value) {
      swal({
        title: 'Delete this address and clear districts?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.onDeleteAddress(value)
        }
      })
    },
    onDeleteAddress(value) {
      let address = value + '_address'
      let house_district = value + '_house_district'
      let sen_district = value + '_sen_district'
      let con_district = value + '_con_district'

      this[address] = null
      this.address_error[`${value}_error`] = false
      this.member[address] = ''
      this.member[house_district] = ''
      this.member[sen_district] = ''
      this.member[con_district] = ''
    },
    onUploadAvatar() {
      const file = this.$refs.avatarUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.member.avatar_url = res.secure_url
          this.member = {...this.member}
          this.onUpdateMember(true)
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    getArrayOfUniqDistricts(array) {
      return _.uniqBy(array, function (e) {
        return e.district;
      });
    },
    getUniqDistricts(array) {
      if (array === null) {
        return
      }
      return _.uniqBy(array)[0];
    },
    async onSelectClient() {
      let districts = (await this.getClientDistricts(this.member.client_id)).data
      let client = this.clientList.find(client => client.id == this.member.client_id)
      if (client && client.state && client.state.few_districts) {
        this.districtList = this.getArrayOfUniqDistricts(this.removeDuplicateDistricts(districts))
      } else {
        this.districtList = districts
      }
    },
    removeDuplicateDistrict(array) {
      if (array === null || array === undefined) {
        return
      }
      return array.map(district =>
        district != null && district.match(/^[A-Z][A-Za-z0-9_]+\w/i)
          ? district.match(/^[A-Z][A-Za-z0-9_]+\w/i)[0]
          : ''
      )
    },
    removeDuplicateDistricts(array) {
      return array.map(district => ({
        ...district,
        district: district.district.match(/^[A-Z][A-Za-z0-9_]+\w/i) ?
          district.district.match(/^[A-Z][A-Za-z0-9_]+\w/i)[0] :
          district.district
      }))
    },
    onChangeAddress(index) {
      if (!this.client) {
        return
      }
      const prefixList = ['home_','work_','work2_','work3_','work4_','mailing_'];
      const prefix = prefixList[index]
      const payload = {
        state_id: this.client.state_id,
        address: this.member[`${prefix}address`],
        is_federal_client: this.isFederalClient ? true : false,
      }
      
      this[`${prefix}address`] = this.member[`${prefix}address`]
      this.searchDistrictByAddress(payload)
        .then((res) => {
          if (!res.data.location.lat && !res.data.location.lng) {
            this.member[`${prefix}lat`] = null
            this.member[`${prefix}lng`] = null
            this.onCallToastr('error', 'Sorry, no valid district found for this address, please add manually', 'Error!')
            return
          }

          let houseDistrict = res.data.house_district
          let senateDistrict = res.data.sen_district
          let conDistrict = res.data.con_district
          let client = this.clientList.find(client => client.id == this.member.client_id)

          if (client && client.state && client.state.few_districts) {
            if (houseDistrict !== null) {
                houseDistrict.district = houseDistrict.district.match(/^[A-Z][A-Za-z0-9_]+\w/i) ? houseDistrict.district.match(/^[A-Z][A-Za-z0-9_]+\w/i)[0] : houseDistrict.district
              }
              if (senateDistrict !== null) {
                senateDistrict.district = senateDistrict.district.match(/^[A-Z]\d+\w/i) ? senateDistrict.district.match(/^[A-Z]\d+\w/i)[0] : senateDistrict.district
              }
              if (conDistrict !== null) {
                conDistrict.district = conDistrict.district.match(/^[A-Z]\d+\w/i) ? conDistrict.district.match(/^[A-Z]\d+\w/i)[0] : conDistrict.district
              }
          }

          if (this.houseDistrictList.length > 0 && houseDistrict) {
              if(this.isFederalClient) {
                this.member[`${prefix}house_district`] = houseDistrict.district
                this.member[`${prefix}house_district_id`] = houseDistrict.id
              } else {
                this.member[`${prefix}house_district`] = houseDistrict.district
              }
          } else {
            this.member[`${prefix}house_district`] = null
          }

          if (this.senDistrictList.length > 0 && senateDistrict) {
              if(this.isFederalClient) {
                this.member[`${prefix}sen_district`] = senateDistrict.district
                this.member[`${prefix}sen_district_id`] = senateDistrict.id
              } else {
                this.member[`${prefix}sen_district`] = senateDistrict.district
              }
          } else {
            this.member[`${prefix}sen_district`] = null
          }

          if (this.conDistrictList.length > 0 && conDistrict) {
            if(this.isFederalClient) {
              this.member[`${prefix}con_district`] = conDistrict.district
              this.member[`${prefix}con_district_id`] = conDistrict.id
            } else {
              this.member[`${prefix}con_district`] = conDistrict.district
            }
          } else {
            this.member[`${prefix}con_district`] = null
          }

          this.member[`${prefix}lat`] = res.data.location.lat
          this.member[`${prefix}lng`] = res.data.location.lng
          this.member = {...this.member}
        })
        .catch((err) => {
          this.onClear(`${prefix}house_district`)
          this.onClear(`${prefix}sen_district`)
          this.onClear(`${prefix}con_district`)
          this.member[`${prefix}lat`] = null
          this.member[`${prefix}lng`] = null
          
          if(this.isFederalClient) {
            this.onClear(`${prefix}house_district_id`)
            this.onClear(`${prefix}sen_district_id`)
            this.onClear(`${prefix}con_district_id`)
          }

          this.handleError(err)
        })
    },
    onClear(key) {
      this.member[key] = ''
      this.member = {...this.member}
    },
    clearAddress() {
      const prefixList = ['home_','work_','work2_','work3_','work4_','mailing_'];

      for (let i = 0; i <= (prefixList.length - 1); i++) {
        let prefix = prefixList[i]
        
        if(this.isFederalClient) {
            this.member[`${prefix}con_district`] = this.member[`${prefix}con_district`]
            this.member[`${prefix}con_district_id`] = this.member[`${prefix}con_district_id`]
            this.member[`${prefix}federal_senate`] = this.member[`${prefix}federal_senate`]
            this.member[`${prefix}federal_senate_id`] = this.member[`${prefix}federal_senate_id`]
            this.member[`${prefix}house_district`] = this.member[`${prefix}house_district`]
            this.member[`${prefix}house_district_id`] = this.member[`${prefix}house_district_id`]
            this.member[`${prefix}sen_district`] = this.member[`${prefix}sen_district`]
            this.member[`${prefix}sen_district_id`] = this.member[`${prefix}sen_district_id`]
          } else {
            this.member[`${prefix}con_district`]
              ? this.member[`${prefix}con_district`] = this.member[`${prefix}con_district`]
              : this.member[`${prefix}con_district`] = ''
            this.member[`${prefix}federal_senate`]
              ? this.member[`${prefix}federal_senate`] = this.member[`${prefix}federal_senate`]
              : this.member[`${prefix}federal_senate`] = ''
            typeof (this.member[`${prefix}house_district`]) == 'string'
              ? this.member[`${prefix}house_district`] = this.member[`${prefix}house_district`]
              : this.member[`${prefix}house_district`] = ''
            typeof (this.member[`${prefix}sen_district`]) == 'string'
              ? this.member[`${prefix}sen_district`] = this.member[`${prefix}sen_district`]
              : this.member[`${prefix}sen_district`] = ''
          }
      }
    },
    updateDistricts() {
      const prefixList = ['home_','work_','work2_','work3_','work4_','mailing_'];

      for (let i = 0; i <= (prefixList.length - 1); i++) {
        let prefix = prefixList[i]
          if(this.isFederalClient){
            this.member[`${prefix}con_district`] = this.member[`${prefix}con_district`];
            this.member[`${prefix}con_district_id`] = this.member[`${prefix}con_district_id`];
            this.member[`${prefix}federal_senate`] = null;
            this.member[`${prefix}house_district`] = null;
            this.member[`${prefix}sen_district`] = null;
          }else {
            typeof (this.member[`${prefix}con_district`]) == 'string'
              ? this.member[`${prefix}con_district`] = this.member[`${prefix}con_district`]
              : this.member[`${prefix}con_district`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}con_district`]))
            typeof (this.member[`${prefix}federal_senate`]) == 'string'
              ? this.member[`${prefix}federal_senate`] = this.member[`${prefix}federal_senate`]
              : this.member[`${prefix}federal_senate`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}federal_senate`]))
            typeof (this.member[`${prefix}house_district`]) == 'string'
              ? this.member[`${prefix}house_district`] = this.member[`${prefix}house_district`]
              : this.member[`${prefix}house_district`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}house_district`]))
            typeof (this.member[`${prefix}sen_district`]) == 'string'
              ? this.member[`${prefix}sen_district`] = this.member[`${prefix}sen_district`]
              : this.member[`${prefix}sen_district`] = this.getUniqDistricts(this.removeDuplicateDistrict(this.member[`${prefix}sen_district`]))
          }
      }
    },
    changeAddress() {
      const prefixList = ['home_','work_','work2_','work3_','work4_','mailing_'];
      let result = false;

      // Clear previous errors
      this.address_error = {};

      prefixList.forEach((prefix) => {
        const address = this.member[`${prefix}address`];
        const hasAddress = !!this.member[`${prefix}address`];
        const hasCoordinates = !!this.member[`${prefix}lat`] && !!this.member[`${prefix}lng`];

        // Check for general address presence
        if (prefix === 'home_' && !this.member.home_address) {
          this.address_error.home_error = true;
          result = true;
        }

        if (prefix === 'mailing_' && !this.member.mailing_address) {
          this.address_error.mailing_error = true;
          result = true;
        }

        if (address && !this.isSuccessAddress(prefix)) {
          this.address_error[`${prefix}error`] = true;
          result = true;
        }
      });

      return result;
    },
    isSuccessAddress(prefix) {
      if (!this.member[`${prefix}address`]) {
        return false;
      }

      if (this.isFederalClient) {
        return !!this.member[`${prefix}con_district_id`];
      }

      return !!(this.member[`${prefix}sen_district`] || this.member[`${prefix}house_district`]);
    },
    next() {
      let stepName = `step${this.step}`
      if (!this.$refs[stepName].validate()) return
      if (this.step === 2 && this.changeAddress()) return
      this.step = this.step + 1
    },
    onUpdateMember() {
      this.isBusy = true
      this.clearAddress()
      this.member.other_interests = []
      for (const [id, hobby] of Object.entries(this.otherHobbies)) {
        this.member.other_interests.push({
          "parent_id": id,
          "name": hobby
        })
      }

      this.member.update_profile = 1
      this.member.universities = this.member.universities.filter(university => university !== undefined).map(university => university.id);
      this.member.interests = this.member.interests.filter(item => item.type !== 'favorite_team')
      this.member.interests = this.member.interests.concat(this.memberFavoriteTeams)
      
      if(this.isFederalClient) {
        // home address
        this.member.home_house_district = this.member.home_house_district
        this.member.home_house_district_id = this.member.home_house_district_id
        const homeHouseDistrict = this.districtList.find(district => district.id === this.member.home_house_district_id)  
        if(homeHouseDistrict) {
          this.member.home_house_district = homeHouseDistrict.district
          this.member.home_house_district_id = homeHouseDistrict.id
        }

        this.member.home_sen_district = this.member.home_sen_district
        this.member.home_sen_district_id = this.member.home_sen_district_id
        const homeSenateDistrict = this.districtList.find(district => district.id === this.member.home_sen_district_id)
        if(homeSenateDistrict) {
          this.member.home_sen_district = homeSenateDistrict.district
          this.member.home_sen_district_id = homeSenateDistrict.id
        }

        this.member.home_con_district = this.member.home_con_district
        this.member.home_con_district_id = this.member.home_con_district_id
        const homeConDistrict = this.districtList.find(district => district.id === this.member.home_con_district_id)
        if(homeConDistrict) {
          this.member.home_con_district = homeConDistrict.district
          this.member.home_con_district_id = homeConDistrict.id
        }

        // work address
        this.member.work_house_district = this.member.work_house_district
        this.member.work_house_district_id = this.member.work_house_district_id
        const workHouseDistrict = this.districtList.find(district => district.id === this.member.work_house_district_id)
        if(workHouseDistrict) {
          this.member.work_house_district = workHouseDistrict.district
          this.member.work_house_district_id = workHouseDistrict.id
        } 

        this.member.work_sen_district = this.member.work_sen_district
        this.member.work_sen_district_id = this.member.work_sen_district_id
        const workSenateDistrict = this.districtList.find(district => district.id === this.member.work_sen_district_id)
        if(workSenateDistrict) {
          this.member.work_sen_district = workSenateDistrict.district
          this.member.work_sen_district_id = workSenateDistrict.id
        }

        this.member.work_con_district = this.member.work_con_district
        this.member.work_con_district_id = this.member.work_con_district_id
        const workConDistrict = this.districtList.find(district => district.id === this.member.work_con_district_id)
        if(workConDistrict) {
          this.member.work_con_district = workConDistrict.district
          this.member.work_con_district_id = workConDistrict.id
        }   

        // work2 address
        this.member.work2_house_district = this.member.work2_house_district
        this.member.work2_house_district_id = this.member.work2_house_district_id
        const work2HouseDistrict = this.districtList.find(district => district.id === this.member.work2_house_district_id)
        if(work2HouseDistrict) {
          this.member.work2_house_district = work2HouseDistrict.district
          this.member.work2_house_district_id = work2HouseDistrict.id
        } 

        this.member.work2_sen_district_id = this.member.work2_sen_district
        this.member.work2_sen_district = this.member.work2_sen_district_id
        const work2SenateDistrict = this.districtList.find(district => district.id === this.member.work2_sen_district_id)
        if(work2SenateDistrict) {
          this.member.work2_sen_district = work2SenateDistrict.district
          this.member.work2_sen_district_id = work2SenateDistrict.id
        } 

        this.member.work2_con_district = this.member.work2_con_district
        this.member.work2_con_district_id = this.member.work2_con_district_id
        const work2ConDistrict = this.districtList.find(district => district.id === this.member.work2_con_district_id)
        if(work2ConDistrict) {
          this.member.work2_con_district = work2ConDistrict.district
          this.member.work2_con_district_id = work2ConDistrict.id
        } 

        // work3 address
        this.member.work3_house_district = this.member.work3_house_district
        this.member.work3_house_district_id = this.member.work3_house_district_id
        const work3HouseDistrict = this.districtList.find(district => district.id === this.member.work3_house_district_id)
        if(work3HouseDistrict) {
          this.member.work3_house_district = work3HouseDistrict.district
          this.member.work3_house_district_id = work3HouseDistrict.id
        }

        this.member.work3_sen_district = this.member.work3_sen_district
        this.member.work3_sen_district_id = this.member.work3_sen_district_id
        const work3SenateDistrict = this.districtList.find(district => district.id === this.member.work3_sen_district_id)
        if(work3SenateDistrict) {
          this.member.work3_sen_district = work3SenateDistrict.district
          this.member.work3_sen_district_id = work3SenateDistrict.id
        }

        this.member.work3_con_district = this.member.work3_con_district
        this.member.work3_con_district_id = this.member.work3_con_district_id
        const work3ConDistrict = this.districtList.find(district => district.id === this.member.work3_con_district_id)
        if(work3ConDistrict) {
          this.member.work3_con_district = work3ConDistrict.district
          this.member.work3_con_district_id = work3ConDistrict.id
        }

        // work4 address
        this.member.work4_house_district = this.member.work4_house_district
        this.member.work4_house_district_id = this.member.work4_house_district_id
        const work4HouseDistrict = this.districtList.find(district => district.id === this.member.work4_house_district_id)
        if(work4HouseDistrict) {
          this.member.work4_house_district = work4HouseDistrict.district
          this.member.work4_house_district_id = work4HouseDistrict.id
        }

        this.member.work4_sen_district = this.member.work4_sen_district
        this.member.work4_sen_district_id = this.member.work4_sen_district_id
        const work4SenateDistrict = this.districtList.find(district => district.id === this.member.work4_sen_district_id)
        if(work4SenateDistrict) {
          this.member.work4_sen_district = work4SenateDistrict.district
          this.member.work4_sen_district_id = work4SenateDistrict.id
        }

        this.member.work4_con_district = this.member.work4_con_district
        this.member.work4_con_district_id = this.member.work4_con_district_id
        const work4ConDistrict = this.districtList.find(district => district.id === this.member.work4_con_district_id)
        if(work4ConDistrict) {
          this.member.work4_con_district = work4ConDistrict.district
          this.member.work4_con_district_id = work4ConDistrict.id
        }
      }
      
      this.updateMember(this.member)
        .then(() => {
          this.isBusy = false;
          this.isModalOpen = false;
          this.getMember();
        })
        .catch((err) => {
          this.errors = err.response && err.response.data && err.response.data.errors && err.response.data.errors
          this.handleError(err)
        })
    },
    errorMessages(field) {
      return (this.errors && this.errors[field]) ? this.errors[field][0] : []
    },
    getPoliticalLeaningsList() {
      this.isBusy = true
      this.getPoliticalLeanings().then(response => {
        this.politicalLeanings = response.data
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    getPoliticalIssuesList() {
      this.isBusy = true
      this.getPoliticalIssues().then(response => {
        this.politicalIssues = response.data
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    getInterestsList() {
      this.isBusy = true
      this.getInterests(this.client.id).then(response => {
        this.hobbiesInterests = response.data
        this.favoriteTeam = this.hobbiesInterests.filter(interest => interest.type === 'favorite_team')
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    getClientCustomFields() {
      this.isBusy = true
      this.getClientFields(this.member.client_id).then(response => {
        this.clientFields = response.data
        this.isBusy = false
      })
    },
    addField(field, val) {
      let meta = val
      if (field.type === 'toggle') meta = {value: val}
      if (field.type === 'text') meta = {value: val}
      this.member.custom_fields.push({
        ...field,
        pivot: {
          metadata: meta
        }
      })
    },
    parseCustomFieldMetadata(member) {
      return {
        ...member,
        custom_fields: member.custom_fields ? member.custom_fields.map(field => {
          return {
            ...field,
            pivot: {metadata: JSON.parse(field.pivot.metadata)}
          }
        }) : []
      }
    },
    fieldHandler(val, field) {
      let test = this.member.custom_fields.some(item => item.id == field.id)
      if (test) {
        this.member.custom_fields = this.member.custom_fields.map(item => {
          let meta = val
          if (field.type === 'toggle') meta = {value: val}
          if (field.type === 'text') meta = {value: val}

          if (item.id == field.id) {
            return {
              ...field,
              pivot: {
                metadata: meta
              }
            }
          } else {
            return {...item}
          }
        })
      } else {
        this.addField(field, val)
      }
    },
    isCheckMemberMailingAddress(){
        const prefix = "mailing_";
        const mailingAddress = this.member[`${prefix}address`];
        const clientInfo = this.member && this.member.client || {};
        const isFederal = !(clientInfo && clientInfo.is_use_state);
        
        if(mailingAddress) {
          const payload = {
            state_id: clientInfo && clientInfo.state_id,
            address: mailingAddress,
            is_federal_client: isFederal ? true : false,
          }

          this[`${prefix}address`] = this.member[`${prefix}address`]
          this.searchDistrictByAddress(payload)
            .then((res) => {
              if (!res.data.location.lat && !res.data.location.lng) {
                this.member[`${prefix}lat`] = null
                this.member[`${prefix}lng`] = null
                this.onCallToastr('error', 'Sorry, no valid district found for this address, please add manually', 'Error!')
                return
              }

              let houseDistrict = res.data.house_district
              let senateDistrict = res.data.sen_district
              let conDistrict = res.data.con_district

              if (clientInfo && clientInfo.state && clientInfo.state.few_districts) {
                if (houseDistrict !== null) {
                    houseDistrict.district = houseDistrict.district.match(/^[A-Z][A-Za-z0-9_]+\w/i) ? houseDistrict.district.match(/^[A-Z][A-Za-z0-9_]+\w/i)[0] : houseDistrict.district
                  }
                  if (senateDistrict !== null) {
                    senateDistrict.district = senateDistrict.district.match(/^[A-Z]\d+\w/i) ? senateDistrict.district.match(/^[A-Z]\d+\w/i)[0] : senateDistrict.district
                  }
                  if (conDistrict !== null) {
                    conDistrict.district = conDistrict.district.match(/^[A-Z]\d+\w/i) ? conDistrict.district.match(/^[A-Z]\d+\w/i)[0] : conDistrict.district
                  }
              }
              
              if (houseDistrict) {
                  if(this.isFederalClient) {
                    this.member[`${prefix}house_district`] = houseDistrict.district
                    this.member[`${prefix}house_district_id`] = houseDistrict.id
                  } else {
                    this.member[`${prefix}house_district`] = houseDistrict.district
                  }
              } else {
                this.member[`${prefix}house_district`] = null
              }

              if (senateDistrict) {
                  if(this.isFederalClient) {
                    this.member[`${prefix}sen_district`] = senateDistrict.district
                    this.member[`${prefix}sen_district_id`] = senateDistrict.id
                  } else {
                    this.member[`${prefix}sen_district`] = senateDistrict.district
                  }
              } else {
                this.member[`${prefix}sen_district`] = null
              }

              if (conDistrict) {
                if(this.isFederalClient) {
                  this.member[`${prefix}con_district`] = conDistrict.district
                  this.member[`${prefix}con_district_id`] = conDistrict.id
                } else {
                  this.member[`${prefix}con_district`] = conDistrict.district
                }
              } else {
                this.member[`${prefix}con_district`] = null
              }

              this.member[`${prefix}lat`] = res.data.location.lat
              this.member[`${prefix}lng`] = res.data.location.lng

            })
            .catch((err) => {
              this.onClear(`${prefix}house_district`)
              this.onClear(`${prefix}sen_district`)
              this.onClear(`${prefix}con_district`)
              this.member[`${prefix}lat`] = null
              this.member[`${prefix}lng`] = null

              if(this.isFederalClient) {
                this.onClear(`${prefix}house_district_id`)
                this.onClear(`${prefix}sen_district_id`)
                this.onClear(`${prefix}con_district_id`)
              }

              this.handleError(err)
            })

          }
    },
    setGeoFieldInputEventListener(){
      this.$refs.mailing_address.$el.addEventListener('input', (event) => this.inputListener(event,'mailing'), false)
      this.$refs.home_address.$el.addEventListener('input', (event) => this.inputListener(event,'home'), false);
      this.$refs.work_address.$el.addEventListener('input', (event) => this.inputListener(event,'work'), false);
      if (this.client) {
        if (this.client.uses_2nd_work) {
          this.$refs.work2_address.$el.addEventListener('input', (event) => this.inputListener(event, 'work2'), false);
        }
        if (this.client.uses_3rd_work) {
          this.$refs.work3_address.$el.addEventListener('input', (event) => this.inputListener(event, 'work3'), false);
        }
        if (this.client.uses_4th_work) {
          this.$refs.work4_address.$el.addEventListener('input', (event) => this.inputListener(event, 'work4'), false);
        }
      }
   },
   resetEventListeners(){
      this.$refs.mailing_address.$el.removeEventListener('input', (event) => this.inputListener(event,'mailing'), false)
      this.$refs.home_address.$el.removeEventListener('input', (event) => this.inputListener(event,'home'), false)
      this.$refs.work_address.$el.removeEventListener('input', (event) => this.inputListener(event,'work'), false)
      if (this.client) {
        if (this.client.uses_2nd_work) {
          this.$refs.work2_address.$el.removeEventListener('input', (event) => this.inputListener(event, 'work2'), false);
        }
        if (this.client.uses_3rd_work) {
          this.$refs.work3_address.$el.removeEventListener('input', (event) => this.inputListener(event, 'work3'), false);
        }
        if (this.client.uses_4th_work) {
          this.$refs.work4_address.$el.removeEventListener('input', (event) => this.inputListener(event, 'work4'), false);
        }
      }
   }
  },
  watch: {
    searchUniversities: function () {
      clearTimeout(this.timer)
      this.timer =  setTimeout(() => {
        this.getUniversity()
      }, 500)
    },
    isModalOpen: function (newValue, oldValue) {
      if (this.isModalOpen) {
        this.step = 1;
      }
    }
  }
}
</script>

<style scoped lang="scss">

.address-input-error::placeholder {
  color: red;
}
.address-input-error {
  border-color: red;
  color: red;
}

::v-deep {
  .v-stepper .v-stepper__label {
    text-align: center;
  }

  .v-stepper .v-stepper__step--complete .v-stepper__label {
    text-align: center;
  }

  .v-stepper .v-stepper__step.v-stepper__step--active .v-stepper__step__step:after {
    background: unset;
  }

  .v-stepper .v-stepper__step.v-stepper__step--inactive .v-stepper__step__step:after {
    background-color: unset !important;
  }
}
.v-slider--horizontal .v-slider__track-container {
  height: 4px;
}

.profile-update__container {
  @media screen and (min-width: 800px) {
    max-width: 800px;
    padding: 25px 15px;
  }
}

.address_icon {
  width: 15px;
}

.v-text-field__details {
  height: 0 !important;
}

.dialog-full-height .title {
  width: 95%;
  text-align: center;
}

.dialog-full-height .v-stepper.v-stepper--is-booted.v-stepper--alt-labels {
  width: 100%;
}

.stepper-scroll-content-box {
  max-height: calc(100vh - 265px);
  overflow-y: scroll;
}
</style>
