<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout
    align-center
    v-if="rowData.member_legislators.length && rowData.kp2member"
  >
    <span class="ml-2 mr-1 no-line-break cursor-pointer">
      {{ rowData.kp2member.nickname }} {{ rowData.kp2member.lastname }}
    </span>
    <div v-if="rowData.kp2member.kp_preference !== 'unknown'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-avatar v-on="on" :color="kpPreferenceColor(rowData.kp2member)" size="10"></v-avatar>
        </template>
        <span>{{ kpPreferenceTitle(rowData.kp2member) }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="rowData.kp2member.political_leaning_id !== null">
        <template v-slot:activator="{ on }">
          <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(rowData.kp2member)" size="10"></v-avatar>
        </template>
        <span>{{ politicalLearningsTitle(rowData.kp2member) }}</span>
      </v-tooltip>
    </div>
    <v-tooltip bottom v-if="!!rowData.kp2member.notes" max-width="450" >
      <template v-slot:activator="{ on }">
        <v-icon size="16" class="mx-1" color="primary" v-on="on">note</v-icon>
      </template>
      <span>{{ rowData.kp2member.notes }}</span>
    </v-tooltip>
    <div v-if="rowData.kp2member.silence">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-icon
              color="#cc38b8"
              size="16"
              class="mx-1"
            >
              mdi-comment-remove-outline
            </v-icon>
          </div>
        </template>
        <span>Silenced</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip bottom v-if="!!rowData.kp2member.cell">
        <template v-slot:activator="{ on }">
          <a :href="'tel:' + rowData.kp2member.cell" v-on="on">
            <v-icon
              v-if="rowData.kp2member.bad_cell"
              color="error"
              size="16"
              class="mx-1"
            >
              no_cell
            </v-icon>
            <v-icon
              v-else
              color="primary"
              size="16"
              class="mx-1"
            >
              phone_iphone
            </v-icon>
          </a>
        </template>
        <span>{{ rowData.kp2member.cell }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!!rowData.kp2member.email">
        <template v-slot:activator="{ on }">
          <a :href="'mailto:' + rowData.kp2member.email" v-on="on">
            <v-icon
              :color="isBad(rowData.kp2member.email) ? 'error' : 'primary'"
              size="16"
              class="mx-1"
            >
              email
            </v-icon>
          </a>
        </template>
        <span>{{ rowData.kp2member.email }}</span>
      </v-tooltip>
    </div>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";

export default Vue.extend({
  mixins: [politicalLeaningsBorder],
  computed: {
    rowData () {
      return this.params.node.data || {}
    },
    politicalLearnings () {
      return this.params.node.data.politicalLearnings || []
    }
  }
})
</script>
