<template>
  <v-dialog max-width="700" v-model="isModalOpen" persistent>
    <v-form ref="newTaskModal" @submit.prevent="onAddTasks()">
      <v-card>
        <v-card-title>
          <span class="title">Add New Task(s)</span>
          <v-spacer />
          <v-icon @click="isModalOpen = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined class="mb-2" :value="true" v-if="error">{{ error }}</v-alert>

          <v-layout wrap>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Assign to all legislators"
                hide-details
                v-model="messageData.isSpecificLegislatorsOnly"
                @change="assignToAllLegislators"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs4 class="px-2" v-if="!messageData.isSpecificLegislatorsOnly && client && client.state && client.state.house_districts > 0">
              <v-checkbox
                color="primary"
                label="House"
                hide-details
                v-model="messageData.isHouseDistrictsOnly"
                @change="onSelectDistrictType"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2" v-if="!messageData.isSpecificLegislatorsOnly && client && client.state && client.state.sen_districts > 0">
              <v-checkbox
                color="primary"
                label="Senate"
                hide-details
                v-model="messageData.isSenateDisctrictsOnly"
                @change="onSelectDistrictType"
              ></v-checkbox>
            </v-flex>
            <v-flex xs4 class="px-2" v-if="!messageData.isSpecificLegislatorsOnly && client && client.state && client.state.has_joint_committees">
              <v-checkbox
                color="primary"
                label="Joint"
                hide-details
                v-model="messageData.isJointDisctrictsOnly"
                @change="onSelectDistrictType"
              ></v-checkbox>
            </v-flex>
            <v-flex xs6 class="px-2" v-for="committee in selectedCommitteeList" :key="committee.id">
              <v-checkbox
                v-if="!messageData.isSpecificLegislatorsOnly"
                color="primary"
                @change="onSelectCommittee(committee)"
                :input-value="selectedCommitteeIdList.includes(committee.id)"
                :label="committee.label"
                hide-details
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 class="mt-3 px-2" v-if="!messageData.isSpecificLegislatorsOnly">
              <v-expansion-panels v-model="isLegsOpen">
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ 'Legislator(s) (' + (messageData.selectedLegislators || []).length + ')' }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-autocomplete
                      v-model="messageData.selectedLegislators"
                      :items="sortLegislatorsList"
                      outlined
                      dense
                      color="blue-grey lighten-2"
                      label="Selected Legislator(s)"
                      item-text="fullname"
                      item-value="member_legislators[0].id"
                      multiple
                      return-object
                      :disabled="isBusy"
                      :loading="isBusy"
                      :search-input.sync="searchInputFull"
                      @change="searchInputFull=''"
                      hide-details
                      :menu-props="{maxHeight: 'calc(100% - 450px)'}"
                    >
                      <template v-slot:selection="{ item }">
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar class="avatar_image">
                            <img :src="getThumbImage(data.item.official_image)">
                          </v-list-item-avatar>
                          <vip-chip
                            v-if="data.item.vip"
                            class="mr-2 mb-1"
                            :legislator="data.item"
                            :show="'chip_code'"
                          >
                          </vip-chip>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                    <div class="mb-2 pt-1" v-if=" messageData.selectedLegislators &&  messageData.selectedLegislators.length">
                      <div class="d-flex flex-wrap">
                        <div v-for="(item, index) of messageData.selectedLegislators" :key="index" class="mr-2">
                          <v-menu
                            v-model="displaySelected[getIndex(item, messageData.selectedLegislators)]"
                            bottom
                            left
                            :close-on-content-click="false"
                            origin="top left"
                            content-class="role_card"
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip
                                close
                                class="mt-2"
                                pill
                                v-on="on"
                                :style=colorsLegislatorsParties(item,legislators)
                                @click:close="removeLegislator(messageData.selectedLegislators.findIndex(id => id === item), messageData.selectedLegislators)"
                              >
                                <v-avatar left>
                                  <v-img class="avatar_image" :src="getThumbImage(getSelectedLegislator(item).official_image)"></v-img>
                                </v-avatar>
                                <vip-chip
                                  v-if="item.vip"
                                  class="mr-2 mb-1"
                                  :legislator="item"
                                  :show="'chip_code'"
                                >
                                </vip-chip>
                                {{ getSelectedLegislator(item).fullname }}
                              </v-chip>
                            </template>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>
          </v-layout>

          <v-layout wrap>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Hide from KP"
                v-model="hideFromKP"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-select
                multiple
                chips
                outlined
                dense
                persistent-hint
                label="Predefined Tasks"
                hint="Check all of the predefined tasks you'd like to track this session"
                item-text="description"
                item-value="id"
                :items="commonTaskList"
                :disabled="customTask.selected"
                v-model="protoIds">
              </v-select>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-checkbox
                color="primary"
                label="Create Your Own Task"
                v-model="customTask.selected"
               :disabled="protoIds.length !== 0"
              ></v-checkbox>
            </v-flex>
            <v-layout wrap v-if="customTask.selected">
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  label="Short Label*"
                  outlined
                  dense
                  counter="15"
                  :rules="nameRules"
                  v-model="customTask.name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-select
                  label="Type*"
                  outlined
                  dense
                  item-text="data_type"
                  item-value="id"
                  :items="taskTypeList"
                  :rules="typeRules"
                  v-model="customTask.task_type_id"
                  @input="">
                </v-select>
              </v-flex>
              <v-flex xs12 class="px-2" v-if="customTask.task_type_id === -1">
                <v-combobox
                  label="Options*"
                  outlined
                  dense
                  hide-selected
                  multiple
                  small-chips
                  :rules="optionsRules"
                  v-model="customTask.options"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 class="px-2">
                <v-text-field
                  label="Longer explanation for KPs"
                  outlined
                  dense
                  v-model="customTask.tooltip"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="px-2">
                <v-textarea label="Internal Notes" outlined dense v-model="customTask.notes"></v-textarea>
              </v-flex>
            </v-layout>
            <v-flex xs12 class="px-2">
              <v-date-picker2
                v-model="deadline"
                label="Deadline"
                dense="dense"
                :max="formattedEndDate"
                is-normal-date
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="secondary" @click.native="closeModal">Close</v-btn>
          <v-btn type="submit" color="primary" :loading="isBusy">Save and Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import SessionService from '@/services/SessionService'
import ClientService from '@/services/ClientService'
import StateService from '@/services/StateService'

import VDatePicker2 from '@/components/common/date-picker'
import VipChip from "@/components/legislators/vip-setting/vip-chip";
import _ from "lodash";

export default {
  props: {
    activeSession: {
      type: Object,
      default: function () {
        return {}
      }
    },
    commonTaskList: {
      type: Array,
      default: function () {
        return []
      }
    },
    taskList: {
      type: Array,
      default: function () {
        return []
      }
    },
    refresh: {
      type: Function
    },
    selectedClient: {
      type: Object,
      default: function () {
        return {}
      }
    },
    committeeList: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  mixins: [SessionService, ClientService, StateService],
  components: {
    VDatePicker2,
    'vip-chip': VipChip,
  },
  data () {
    return {
      isModalOpen: false,
      hideFromKP: false,
      isLegsOpen: false,
      protoIds: [],
      customTask: {},
      displaySelected: {},
      searchInputFull: '',
      error: '',
      isBusy: false,
      legislators: [],
      messageData: {},
      selectedCommitteeIdList: [],
      deadline: null,
    }
  },
  computed: {
    formattedEndDate() {
      return this.activeSession && this.activeSession.end_at
        ? moment.utc(this.activeSession.end_at).toISOString()
        : ''
    },
    isFederalClient(){
      return this.client && this.client.is_use_state === 0;
    },
    sortLegislatorsList() {
      if(this.isFederalClient) {
        const federalLegislators = this.legislators.filter(legislator => legislator.is_federal === 1)
        return this.checkAndSortArray(federalLegislators, 'Federal')
      }
      const house = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
      const senate = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
      return [
        ...this.checkAndSortArray(house, 'House'),
        ...this.checkAndSortArray(senate, 'Senate')
      ]
    },
    client() {
      return this.isSuperAdmin ? this.selectedClient : this.userClient
    },
    nameRules () {
      return [
        v => !!v || 'Name is required.',
        v => (!!v && v.length <= 15) || 'Name cannot exceed 15 characters.'
      ]
    },
    typeRules () {
      return [
        v => !!v || 'Type is required.'
      ]
    },
    optionsRules () {
      return [
        v => (!!v && v.length > 0) || 'Options are required.'
      ]
    },
    taskTypeList () {
      return [
        {
          id: 1,
          data_type: 'Yes/No',
        },
        {
          id: 2,
          data_type: 'Custom Text',
        },
        {
          id: -1,
          data_type: 'Dropdown List',
        },
      ]
    },
    selectedCommitteeList () {
      let selectedCommitteeList = []
      if (this.messageData.isHouseDistrictsOnly) {
        selectedCommitteeList = [...this.committeeList.filter(committee => committee.type === 0 && (this.client.house_committees || []).includes(committee.id))]
      }
      if (this.messageData.isSenateDisctrictsOnly) {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList.filter(committee => committee.type === 1 && (this.client.senate_committees || []).includes(committee.id))
        ]
      }
      if (this.messageData.isJointDisctrictsOnly) {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList.filter(committee => committee.type === 2 && (this.client.joint_committees || []).includes(committee.id))
        ]
      }
      const committeeTypeList = ['H', 'S', 'J']
      selectedCommitteeList.forEach((committee) => {
        committee.label = `${committee.fullname} (${committeeTypeList[committee.type]})`
      })
      return selectedCommitteeList
    },
  },
  methods: {
    toggle () {
      this.isLegsOpen = false
      this.error = ''
      this.messageData.selectedLegislators = []
      const params = {
        kp1: 1, //adam added this back since it caused issue in the member_legislator_id field which is required 
        order: 'legislators.lastname'
      }
      return this.getClientLegislatorsWithCommittees(this.selectedClient.id, params).then((response) => {
        this.legislators = response.data.data ? response.data.data : response.data
         for (let i = 0; i < this.legislators.length; i++) {
          if (this.legislators[i].member_legislators.length && this.legislators[i].member_legislators[0].kp_position == 1) {
            this.messageData.selectedLegislators.push(this.legislators[i].member_legislators[0].leg_id)
          }
        }
        this.messageData.isSpecificLegislatorsOnly = true
        this.isModalOpen = true
      }, error => {
        this.error = error
      })
    },
    checkAndSortArray(array, name) {
      if (array.length) {
        array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
        array.push({divider: true})
        array.unshift({header: name})
      }
      return array
    },
    resetTask() {
      this.hideFromKP = false
      this.isLegsOpen = false
      this.protoIds = []
      this.customTask = {}
      this.displaySelected = {}
      this.searchInputFull = ''
      this.error = ''
      this.legislators = []
      this.messageData = {}
      this.selectedCommitteeIdList = []
      this.deadline = null
    },
    closeModal () {
      this.isModalOpen = false
      this.resetTask()
    },
    onAddTasks () {
      this.error = ''
      if (!this.$refs.newTaskModal.validate()) return
      if (this.protoIds.length === 0 && !this.customTask.selected) {
        this.error = 'Please select the predefined tasks or create your own task.'
        return
      }
      this.isBusy = true
      const payload = {}
      payload.hide_from_kp = this.hideFromKP || false
      payload.sessionId = this.activeSession.id
      payload.proto_ids = this.protoIds || []
      if (this.customTask.selected) {
        payload.custom_task = this.customTask
        payload.custom_task.options = payload.custom_task.options || []
      }
      payload.custom_task = payload.custom_task || null
      payload.legislators = this.messageData.selectedLegislators || []
      payload.deadline = this.deadline
      if(this.messageData.selectedLegislators.some(item => item.fullname)) {
        payload.legislators = this.messageData.selectedLegislators.map(legislator => { return legislator.id}) || []
      }

      this.addSessionTasks(payload)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'New tasks has been added to active session.', 'Success!')
          this.refresh()
          this.protoIds = []
          this.customTask = {}
          this.closeModal()
        })
        .catch((err) => {
          this.isBusy = false
          this.handleError(err)
        })
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    getSelectedLegislator(item) {
      if (typeof item === 'object' && item.id) {
        item = item.id
      }
      return this.legislators.find(legislator => item == legislator.id);
    },
    removeLegislator(index, array) {
      array.splice(index, 1)
    },
    assignToAllLegislators() {
      if (!this.messageData.isSpecificLegislatorsOnly) {
        this.messageData.isHouseDistrictsOnly = false
        this.messageData.isSenateDisctrictsOnly = false
        this.messageData.isJointDisctrictsOnly = false
        this.messageData.selectedLegislators = []
        this.selectedCommitteeIdList = []
      } else {
        this.messageData.selectedLegislators = []
        for (let i = 0; i < this.legislators.length; i++) {
          if (this.legislators[i].member_legislators.length && this.legislators[i].member_legislators[0].kp_position == 1) {
            this.messageData.selectedLegislators.push(this.legislators[i].member_legislators[0].leg_id)
          }
        }
      }
      this.messageData = _.cloneDeep(this.messageData)
    },
    onSelectDistrictType () {
      this.loading = true
      let selectedCommitteeIdList = []
      if (this.messageData.isJointDisctrictsOnly) {
        selectedCommitteeIdList = [
          ...selectedCommitteeIdList
        ]
      }
      this.selectedCommitteeIdList = selectedCommitteeIdList
      this.onFilterLegislators()
      this.loading = false
    },
    onSelectCommittee(committee) {
        if (this.selectedCommitteeIdList.includes(committee.id)) {
          this.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.selectedCommitteeIdList, committee.id)
        } else {
          this.selectedCommitteeIdList.push(committee.id)
        }
      this.onFilterLegislators()
    },
    onSelectCommitteeIdList(arr, id) {
      return arr.filter(_committeeId => _committeeId !== id)
    },
    onFilterLegislators() {
      this.loading = true
      let selectedLegislators = this.legislators.filter((legislator) => {
        if (this.selectedCommitteeIdList.length === 0) {
          if(this.isFederalClient) {
            if (this.messageData.isHouseDistrictsOnly && this.messageData.isSenateDisctrictsOnly) {
              return true
            } else if (this.messageData.isHouseDistrictsOnly) {
              return legislator.district.district.startsWith('CD')
            } else if (this.messageData.isSenateDisctrictsOnly) {
              return !legislator.district.district.startsWith('CD')
            }
          }else{
            if (this.messageData.isHouseDistrictsOnly && this.messageData.isSenateDisctrictsOnly) {
              return legislator.district.district[0] === 'H' || legislator.district.district[0] === 'S'
            } else if (this.messageData.isHouseDistrictsOnly) {
              return legislator.district.district[0] === 'H'
            } else if (this.messageData.isSenateDisctrictsOnly) {
              return legislator.district.district[0] === 'S'
            }
          }
          
        }

        if (!legislator.legislator_committees || legislator.legislator_committees.length < 1) return false

        const subCommitteeIds = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const committeeId = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const mainFilter = this.selectedCommitteeIdList.find(committee => committeeId.includes((committee)))
        if (mainFilter) return true

        if (subCommitteeIds.length === 0 && committeeId.length === 0) return false
      })

      this.messageData.selectedLegislators = selectedLegislators
      this.messageData = _.cloneDeep(this.messageData)
      this.loading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content .theme--light .v-menu__content--fixed .menuable__content__active .v-autocomplete__content {
  height: 100px;
}
</style>
