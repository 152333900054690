<template>
  <v-dialog width="1000" v-model="isModalOpen" scrollable persistent>
      <v-form v-if="isModalOpen" ref="legislatorForm" class="flex-1" @submit.prevent="onUpdateLegislator()">
        <v-card :loading="isBusy">
          <v-tabs
            :show-arrows="isMobile"
            v-model="tabs"
          >
            <v-tab>
              <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-square-user</v-icon>
              General
            </v-tab>
            <v-tab>
              <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-landmark</v-icon>
              Official
            </v-tab>          
            <v-tab>
              <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-users</v-icon>
              Staff
            </v-tab>
            <v-tab>
              <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-book-user</v-icon>
              Personal
            </v-tab>
            <v-tab>
              <v-icon :size="isMobile? 18 : 24" left>fa-thin fa-globe</v-icon>
              Web
            </v-tab>
            <v-tab>
              <v-icon :size="isMobile? 18 : 24" left>fa-brands fa-twitter</v-icon>
              Social
            </v-tab>
            <v-tab>
              <v-icon :size="isMobile? 18 : 24" left>fa-calendar-alt</v-icon>
              Dates
            </v-tab>
          </v-tabs>
          <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>

          <v-card-text class="py-3">
            <v-tabs-items v-model="tabs" class="full-height-tab">
            
              <!-- General -->
              <v-tab-item class="py-4">
              <v-container>
                <v-row
                  cols="12"
                >
                  <v-col xs12 md6 class="align-center px-2">
                    <!--<small class="grey--text">Support Rating (1=unsupportive, 5=very supportive) Admins only</small>-->
                      <v-avatar
                        size="120"
                        class="bordered_avatar"
                      >
                        <img
                          v-if="legislator.official_image && legislator.official_image[8] === 'r'"
                          alt="Avatar"
                          :src="getThumbImageMedium(legislator.official_image)"
                        />
                        <img
                          v-if="legislator.official_image && legislator.official_image[8] !== 'r'"
                           alt="Avatar"
                           class="avatar_height-auto"
                           :src="legislator.official_image"
                        />
                        <img
                          v-if="!legislator.official_image"
                           alt="Avatar"
                          :src="'/images/member-avatar.jpg'"
                        />
                      </v-avatar>

                      <div class="upload-btn ml-3" >
                        <v-flex xs12 class="px-1 mb-4">
                          <v-rating
                            v-model="legislator.leg_data.leg_support"
                            active-color="#ffcc80"
                            color="#ffcc80"
                            size="20"
                          ></v-rating>
                        </v-flex>
                        <v-btn color="primary" class="mr-3" @click="$refs.officialImageUploader.click()">Upload new photo</v-btn>
                        <input
                            ref="officialImageUploader"
                            type="file"
                            accept="image/*"
                            class="d-none"
                            @change="onUploadOfficialImage()"
                        >
                      </div>
                    </v-col>
                    
                 <!-- START VIP and Lame Duck switches --> 
                  <v-col xs12 md6 class="px-2">
                    <v-row cols="6" >
                      <v-col xs6 md3>
                      <v-switch
                      :class="isMobile? 'mt-0' : ''"
                      color="primary"
                      label="Lame Duck"
                      v-model="legislator.lame_duck"
                    ></v-switch>
                  </v-col>
                  <v-col xs6 md3 class="px-2">
                    <v-switch
                      :class="isMobile? 'mt-0' : ''"
                      color="primary"
                      label="VIP"
                      :input-value="legislator.vip"
                      @change="disableVip"
                    ></v-switch>
                  </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="legislator.vip"
                      xs12 md5
                      class="mb-0 ml-auto px-2" :class="{ 'mt-2': isMobile }"
                    >
                      <h5 v-if=" legislator.vip_start" class="text-center">VIP since {{ formatDate(legislator.vip_start) }}</h5>
                      <v-textarea
                        rows="2"
                        outlined
                        label="VIP Notes*"
                        hint="Example: 'Speaker of the House', or 'Senate Majority Leader'"
                        v-model="legislator.vip_note"
                        :rules="vipRules"
                        dense
                      ></v-textarea>
                    </v-col>
                </v-row>
              </v-col>
              </v-row>
              </v-container>
                <!-- END VIP and Lame Duck switches -->
                <!-- moved lame duck and vip switches from here Adam 8.5.22 -->
              
              <v-layout wrap>
                    <v-flex sm6 class="px-2">
                          <v-text-field
                            label="First Name*"
                            outlined
                            :rules="nickNameRules"
                            v-model="legislator.nickname"
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex sm6 class="px-2">
                        <v-text-field
                          label="Last Name*"
                          outlined
                          :rules="lastNameRules"
                          v-model="legislator.lastname"
                          dense
                        ></v-text-field>
                        </v-flex>
                </v-layout>
                <v-layout v-if="isSuperAdmin" wrap>
                    <v-flex sm3 class="px-2">
                        <v-select
                          v-if="isSuperAdmin"
                          label="Party"
                          outlined
                          :items="partyList"
                          dense
                          v-model="legislator.party"
                        ></v-select>
                      </v-flex>
                      <v-flex sm3 class="px-2">
                        <v-select
                          v-if="isSuperAdmin"
                          label="District*"
                          outlined
                          item-text="district"
                          item-value="id"
                          :items="districtList"
                          dense
                          v-model="legislator.district_id"
                        ></v-select>
                      </v-flex>
                      <v-flex sm3 class="px-2">
                        <v-select
                          v-if="isSuperAdmin"
                          label="State"
                          dense
                          item-text="state"
                          item-value="id"
                          :items="filteredStateList"
                          :rules="stateRules"
                          outlined
                          v-model="legislator.state_id"
                          @input="onSelectState"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex sm3 class="px-2">
                        <v-select
                          v-if="isSuperAdmin"
                          label="Country"
                          dense
                          item-text="state"
                          item-value="id"
                          :items="countryList"
                          :rules="countryRules"
                          outlined
                          v-model="countryId"
                        >
                        </v-select>
                      </v-flex>
                     <v-flex sm3 class="px-2"> 
                <v-checkbox 
                          v-if="isSuperAdmin"
                          dense
                          :class="isMobile? 'mt-0' : ''"
                          color="primary"
                          label="Federal"
                          v-model="legislator.is_federal"
                          @change="onChangeIsFederalHandler"
                        ></v-checkbox>
                      </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex md6 class="px-2 align-start">
                    <v-textarea outlined hint="Examples: 'contact aide first' or 'has 3 kids who play baseball'" label="Helpful Legislator Notes (seen by all users)" rows="5" v-model="legislator.leg_data.leg_notes"></v-textarea>
                  </v-flex>
                  <v-flex md6 class="px-2 align-start" v-if="isSuperAdmin || isVIP">
                    <v-textarea outlined hint="Example: 'will lose next election'" label="Private/Sensitive Legislator Notes (Admins only)" rows="5" v-model="legislator.leg_data.vip_notes"></v-textarea>
                  </v-flex>
                </v-layout>
                <!--<v-flex xs6 class="px-2" v-if="!isSuperAdmin && userClient.uses_regions">
                  <v-text-field
                    label="Region"
                    v-model="legislatorRegion"
                    readonly
                  >
                  </v-text-field>
                </v-flex>-->
              </v-tab-item>

              <!-- Official -->
              <v-tab-item class="py-4">
              <h3>Capitol Info</h3>
                <v-layout wrap>
                  <v-flex sm12 class="px-2">
                    <v-text-field
                      class="mt-0"
                      placeholder="Capitol Address"
                      prepend-icon="fa-thin fa-building-columns"
                      outlined
                      persistent-hint
                      hint="Capitol Address"
                      v-model="legislator.official_address"
                      :dense="true"
                    ></v-text-field>
                  </v-flex>
                    <v-flex v-if="isSuperAdmin || !isFederalLegislator" sm6 class="px-2">
                      <v-text-field
                        label="Official Email"
                        prepend-icon="fa-thin fa-circle-envelope"
                        outlined
                        v-model="legislator.official_email"
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex sm6 class="px-2">
                      <v-text-field
                        label="Capitol Phone"
                        prepend-icon="fa-thin fa-phone-office"
                        outlined
                        v-mask="'(###) ###-####'"
                        :rules="phoneNumberRules"
                        v-model="legislator.official_phone"
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex sm6 class="px-2">
                      <v-text-field
                        label="State Room"
                        prepend-icon="fa-thin fa-map-pin"
                        outlined
                        v-model="legislator.state_room"
                        dense
                      ></v-text-field>
                    </v-flex>
                    <!-- <v-flex sm6 class="px-2">
                      <v-text-field
                        label="Aide Name"
                        prepend-icon="fa-thin fa-user-hair"
                        outlined
                        v-model="legislator.aide_name"
                        dense
                      ></v-text-field>
                    </v-flex> -->
                    </v-layout>
                    <v-layout wrap>
                      <h3>District Info</h3>
                     <v-flex sm12 class="px-2">
                       <v-text-field
                         class="mt-0"
                         placeholder="District Address"
                         prepend-icon="fa-thin fa-building"
                         v-model="legislator.local_address"
                         outlined
                         :dense="true"
                       ></v-text-field>
                    </v-flex>
                    <v-flex sm6 class="px-2">
                      <v-text-field
                        label="District Phone"
                        prepend-icon="fa-thin fa-phone-intercom"
                        outlined
                        v-mask="'(###) ###-####'"
                        :rules="phoneNumberRules"
                        v-model="legislator.local_phone"
                        dense
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="isFederalLegislator" wrap>
                    <h3>Addresses</h3>
                    <v-flex sm12 class="px-2 py-1">
                      <other-addresses-table :addresses="filterNotMainAddresses(legislator.addresses)" />
                    </v-flex>
                  </v-layout>
              </v-tab-item>

              <!-- Staff -->
              <v-tab-item class="py-4">
                <Staff 
                  :staffs="staffData.staffs" 
                  :issueList="issueList"
                  :roleList="roleList"
                  :pagination="staffPagination"
                  :loading="isBusy"
                  @update-pagination="getStaff"
                  @add-staff="handleAddStaff"
                  @update-staff="handleUpdateStaff"
                  @modified-staff="(updatedVal) => modifiedStaff = updatedVal"
                  @editing-staff="(editingStaff) => isEditingStaff = editingStaff"
                />
              </v-tab-item>

              <!-- Personal -->
              <v-tab-item class="py-4">
                <v-layout wrap>
                    <v-flex sm12 class="px-2 py-2">
                      <v-textarea
                        auto-grow
                        prepend-icon="fa-thin fa-id-card"
                        rows="3"
                        outlined
                        counter
                        :rules="occupationRules"
                        label="Occupation / Short Bio (500 ch max)"
                        v-model="legislator.occupation"
                        dense
                      ></v-textarea>
                    </v-flex>
                    <v-flex sm6 class="px-2">
                      <v-text-field
                        label="Personal Email"
                        prepend-icon="fa-thin fa-at"
                        outlined
                        v-model="legislator.personal_email"
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex sm6 class="px-2">
                      <v-text-field
                        label="Cell Phone"
                        prepend-icon="fa-thin fa-mobile-button"
                        outlined
                        v-mask="'(###) ###-####'"
                        :rules="phoneNumberRules"
                        v-model="legislator.leg_data.cell_phone"
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex sm6 class="px-2">
                      <v-text-field
                        label="Home Phone"
                        prepend-icon="fa-thin fa-phone"
                        outlined
                        v-mask="'(###) ###-####'"
                        :rules="phoneNumberRules"
                        v-model="legislator.home_phone"
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex sm6 class="px-2">
                      <v-text-field
                        label="Work Phone"
                        prepend-icon="fa-thin fa-phone-office"
                        outlined
                        v-mask="'(###) ###-####'"
                        :rules="phoneNumberRules"
                        v-model="legislator.work_phone"
                        dense
                      ></v-text-field>
                    </v-flex>

                  <v-flex col-12 class="px-2">
                    <h5 class="ml-1 mb-4">You may enter as many as necessary</h5>
                    <v-autocomplete
                      label="Colleges or Universities Attended"
                      color="blue-grey lighten-2 mt-3"
                      :menu-props="{closeOnContentClick:true}"
                      :value="legislator.universities"
                      @change="setUniversity"
                      :search-input.sync="searchUniversities"
                      :items="universities"
                      item-text="name"
                      item-value="id"
                      :loading="isBusy"
                      hide-details
                      return-object
                      outlined
                      multiple
                      filled
                      dense
                    >
                      <template v-slot:selection="{item}"></template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                    <div class="mb-2 pt-1">
                      <div class="d-flex flex-wrap">
                        <div v-for="(item, index) of legislator.universities" class="mr-2">
                          <v-menu class="my-1"
                                  v-model="displaySelected[getIndex(item.id, legislator.universities)]"
                                  :key="index"
                                  bottom
                                  left
                                  :close-on-content-click="false"
                                  origin="top left"
                                  content-class="role_card"
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip
                                class="my-1"
                                v-on="on"
                                close
                                outlined
                                color="primary"
                                :input-value="item.name"
                                @click:close="removeUniversityFromLegislator(index)"
                              >
                                {{ item.name }}
                              </v-chip>
                            </template>
                          </v-menu>
                        </div>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex col-12 class="px-2 mb-2">
                    <div class="title mb-2">
                    Connections:
                    <span v-if="selectedLegislators && selectedLegislators.length">({{ selectedLegislators.length }})</span>
                  </div>
                  <v-autocomplete
                    v-model="selectedLegislators"
                    :items="filterLegislatorsList()"
                    outlined
                    dense
                    color="blue-grey lighten-2"
                    label="Select legislators"
                    item-text="fullname"
                    multiple
                    return-object
                    :disabled="isBusy"
                    :loading="isBusy"
                    :search-input.sync="searchInputFull"
                    @change="searchInputFull=''"
                    hide-details
                    :menu-props="{maxHeight: '50%'}"
                  >
                    <template v-slot:selection="{item}">
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar class="avatar_image">
                          <img :src="data.item.official_image ? getThumbImage(data.item.official_image) : '/images/member-avatar.png'">
                        </v-list-item-avatar>
                        <v-icon v-if="data.item.lame_duck" color="#EECB23">fa-solid fa-duck</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.fullname }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <div class="mb-2 pt-1" v-if="selectedLegislators && selectedLegislators.length">
                    <div class="d-flex flex-wrap">
                      <div v-for="(item, index) of selectedLegislators" class="mr-2">
                        <v-menu
                          v-model="displaySelectedLegislators[getIndex(item.id, selectedLegislators)]"
                          :key="index"
                          bottom
                          left
                          :close-on-content-click="false"
                          origin="top left"
                          content-class="role_card"
                        >
                          <template v-slot:activator="{ on }">
                            <v-chip
                              :color="'default'"
                              class="mt-2"
                              pill
                              v-on="on"
                              :style=colorsLegislatorsParties(item)
                            >
                              <v-avatar left>
                                <v-img class="avatar_image" :src="getThumbImage(item.official_image)"></v-img>
                              </v-avatar>
                              <vip-chip
                                v-if="item.vip || (item.vip_status && item.vip_status[0])"
                                class="mr-2 mb-1"
                                :legislator="item"
                                :show="'chip_code'"
                              >
                              </vip-chip>
                              {{ item.fullname }}
                              <v-tooltip bottom v-if="item.lame_duck">
                                <template v-slot:activator="{ on }">
                                  <a v-on="on">
                                    <v-icon color="#EECB23" size="16" class="ml-1">fa-solid fa-duck</v-icon>
                                  </a>
                                </template>
                                <span>Lame Duck</span>
                              </v-tooltip>
                              <v-icon small class="ml-3" @click="removeLegislator(index, selectedLegislators, item)">close</v-icon>
                            </v-chip>
                          </template>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                  </v-flex>

                  <v-flex class="px-4" xs12 sm12 v-if="client.uses_interests">
                    <h4>Hobbies/Interests:</h4>
                    <div
                      v-for="interest in hobbiesInterests"
                      :key="interest.id"
                    >
                      <v-checkbox
                        v-if="interest.parent_id === null"
                        color="primary"
                        :label="interest.name"
                        class="mt-0"
                        :input-value="isEmptyInterest(interest)"
                        @change="editLegislatorInterests($event, interest)"
                      ></v-checkbox>
                      <div
                        class="ml-4"
                        v-for="childInterest in hobbiesInterests"
                        :key="childInterest.id"
                      >
                        <v-checkbox
                          v-if="interest.id === childInterest.parent_id
                            && isEmptyInterest(interest)
                            && childInterest.type !== 'favorite_team'
                            && childInterest.type !== 'note_hobby'"
                          color="primary"
                          :label="childInterest.name"
                          class="mt-0"
                          :input-value="isEmptyInterest(childInterest)"
                          @change="editLegislatorInterests($event, childInterest)"
                        ></v-checkbox>
                        <div
                          v-if="interest.id === childInterest.parent_id
                          && childInterest.type === 'watching_sports'
                          && isEmptyInterest(childInterest)
                          && isEmptyInterest(interest)"
                        >
                          <favorite-teams-autocomplete
                            :clientId="client.id"
                            :memberFavoriteTeams="legislatorFavoriteTeams"
                            @response="changeLegislatorFavoriteTeams"
                            @remove="removeMemberFavoriteTeam"
                          ></favorite-teams-autocomplete>
                        </div>
                        <div
                          v-if="childInterest.type === 'other'
                                  && interest.id === childInterest.parent_id
                                  && isEmptyInterest(interest)
                                  && isEmptyInterest(childInterest)"
                        >
                          <v-textarea
                            v-model="otherHobbies[childInterest.id]"
                            label="Your Hobby"
                            auto-grow
                            outlined
                            rows="1"
                            row-height="15">
                          </v-textarea>
                        </div>
                      </div>
                      <div
                        v-if="interest.parent_id === null
                          && interest.type === 'other'
                          && isEmptyInterest(interest)"
                      >
                        <v-textarea
                          v-model="otherHobbies[interest.id]"
                          label="Your Hobby"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15">
                        </v-textarea>
                      </div>
                    </div>
                  </v-flex>

                  </v-layout>
              </v-tab-item>

                <!-- web -->
                <v-tab-item class="py-4">
                  <v-layout wrap>
                    <v-flex sm12 class="px-2 py-2">
                      <v-text-field prepend-icon="fa-thin fa-globe" label="Official URL" outlined v-model="legislator.website_url" dense hint="The official website for this legislator"></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-thin fa-book-user" label="Biography URL" outlined v-model="legislator.biography_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-thin fa-browser" label="Campaign URL" outlined v-model="legislator.campaign_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-link" label="Contact Form URL" outlined v-model="legislator.contact_form_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-thin fa-b" label="Ballotpedia URL" outlined v-model="legislator.ballotpedia_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-link" label="OpenSecrets URL" outlined v-model="legislator.opensecrets_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-thin fa-square-pen" label="Custom Link" outlined v-model="legislator.leg_data.custom_link" dense></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-tab-item>

                <!-- Social Media -->
                <v-tab-item class="py-4">
                  <v-layout wrap>
                    <v-flex sm12 class="px-2  py-2">
                      <v-text-field prepend-icon="fa-brands fa-instagram" label="Instagram URL" outlined v-model="legislator.instagram_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-brands fa-linkedin" label="LinkedIn URL" outlined v-model="legislator.linkedin_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-brands fa-twitter" label="Twitter URL" outlined v-model="legislator.twitter_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-brands fa-facebook" label="Facebook URL" outlined v-model="legislator.facebook_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-brands fa-youtube" label="YouTube URL" outlined v-model="legislator.youtube_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-link" label="Truth Social URL" outlined v-model="legislator.truthsocial_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-link" label="Bluesky URL" outlined v-model="legislator.bluesky_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-brands fa-tiktok" label="Tiktok URL" outlined v-model="legislator.tiktok_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-brands fa-threads" label="Threads URL" outlined v-model="legislator.threads_url" dense></v-text-field>
                    </v-flex>
                    <v-flex sm12 class="px-2">
                      <v-text-field prepend-icon="fa-link" label="Eventbrite URL" outlined v-model="legislator.eventbright_url" dense></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-tab-item>

                <!-- End Dates -->
                <v-tab-item class="py-4">
                  <v-layout wrap>
                    <v-flex sm6 class="px-2 py-2">
                      <v-date-picker2
                        dense
                        :disabled="!isSuperAdmin"
                        label="Sworn In (Admin Only)"
                        :rules="electedAtRules"
                        v-model="legislator.elected_at"
                        class="date-picker"
                        is-normal-date
                      ></v-date-picker2>
                    </v-flex>
                    <v-flex sm6 class="px-2 py-2">
                      <v-date-picker2
                        dense
                        v-model="legislator.abdicated_at"
                        :disabled="!isSuperAdmin &&
                          ((legislator.member_legislators &&
                          legislator.member_legislators.length > 0) || (legislator.primary_kp &&
                          !!legislator.primary_kp.client_id))"
                        label="Abdicated (Admin Only)"
                        class="date-picker"
                        is-normal-date
                      ></v-date-picker2>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
            </v-tabs-items>

          </v-card-text>


          <v-card-actions v-if="!isEditingStaff">
            <v-spacer />
            <v-btn outlined color="secondary" @click="isModalOpen = false" class="mt-2">Close</v-btn>
            <v-btn type="submit" color="primary" class="mr-3 mt-2">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
  </v-dialog>
</template>

<script>
import CloudinaryService from '@/services/CloudinaryService'
import CommitteeService from '@/services/CommitteeService'
import LegislatorService from '@/services/LegislatorService'
import StateService from '@/services/StateService'
import FavoriteTeamsAutocomplete from "@/components/auth/favorite-teams-autocomplete";
import VipChip from "@/components/legislators/vip-setting/vip-chip"
import Staff from "@/components/legislators/staff"


import AddressInput from '@/components/common/address-input'
import VDatePicker2 from '@/components/common/date-picker'
import VRating2 from '@/components/common/rating'

import swal from 'sweetalert2'
import {mapGetters} from "vuex";
import InterestService from "@/services/InterestService";
import UniversityService from "@/services/UniversityService";
import ClientService from '@/services/ClientService'
import OtherAddressesTable from '@/components/legislators/other-addresses-table'

export default {
  props: ['stateList', 'countryList', 'committeeList', 'occupationList', 'refresh'],
  mixins: [CloudinaryService, CommitteeService, LegislatorService, StateService, InterestService, UniversityService, ClientService],
  components: {
    AddressInput,
    VDatePicker2,
    VRating2,
    "favorite-teams-autocomplete": FavoriteTeamsAutocomplete,
    'vip-chip': VipChip,
    Staff,
    OtherAddressesTable,
  },
  data() {
    return {
      tabs: null,
      isModalOpen: false,
      activeTabIndex: 0,
      districtList: [],
      displaySelected: {},
      displaySelectedLegislators: {},
      hobbiesInterests: [],
      otherHobbies: {},
      legislatorRegion: '',
      legislatorFavoriteTeams: [],
      searchUniversities: '',
      universities: [],
      legislator: {
        connections: [],
        leg_data: {
          cell_phone: '',
          custom_link: '',
        },
        staff_data: null
      },
      error: '',
      isBusy: false,
      initModalLoading: false,
      selectedLegislators: [],
      searchInputFull: '',
      legislatorList: [],
      staffData: {
        "staffs": [],
        "updatedStaffs": [],
        "newlyAddedStaffs": []
      },
      issueList: [],
      roleList: [],
      modifiedStaff: false,
      staffPagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 10
      },
      isEditingStaff: false,
      countryId: null,
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    stateRules() {
      return [
        v => !!v || 'State is required.',
      ]
    },
    countryRules() {
      return [
        v => !!v || 'Country is required.',
      ]
    },
    filteredStateList(){
      if(!this.countryId || !Array.isArray(this.stateList)){
        return [];
      }

      return this.stateList.filter(state => state.state_of === this.countryId)
    },
    vipRules() {
      return [
        v => !!v || 'VIP notes are required.',
      ]
    },
    lastNameRules() {
      return [
        v => !!v || 'Last name is required.',
      ]
    },
    nickNameRules() {
      return [
        v => !!v || 'Nickname is required.',
      ]
    },
    electedAtRules() {
      return [
        v => !!v || 'Sworn in date is required.',
      ]
    },
    occupationRules() {
      return [
        v => (!v || !!v && v.length <= 500) || 'Max 500 characters'
      ]
    },
    tabItemList() {
      return [
        {
          icon: 'account_box',
          title: 'General',
        },
        {
          icon: 'account_balance',
          title: 'Official',
        },
        {
          icon: 'users',
          title: 'Staff',
        },
        {
          icon: 'home',
          title: 'Local',
        },
        {
          icon: 'public',
          title: 'Web Links',
        },
        {
          iconUrl: '/images/icon-twitter.png',
          title: 'Social Media',
        },
        {
          icon: 'date_range',
          title: 'Dates',
          hide: !this.isSuperAdmin
        },
      ]
    },
    partyList() {
      return ['None', 'R', 'D', 'I', 'L']
    },
    selectedState() {
      if (!this.legislator.state_id) return null
      return this.stateList.find(state => state.id === this.legislator.state_id)
    },
    legislatorCommitteeList() {
      if (this.isSuperAdmin) return []
      if (!this.legislator.district_id) return []
      const district = this.districtList.find(district => district.id === this.legislator.district_id)
      if (!district) return []
      const houseCommitteeList = this.committeeList.filter(committee => (this.userClient.house_committees || []).includes(committee.id))
      const senateCommitteeList = this.committeeList.filter(committee => (this.userClient.senate_committees || []).includes(committee.id))
      const jointCommitteeList = this.committeeList.filter(committee => (this.userClient.joint_committees || []).includes(committee.id))
      if (district.district[0] === 'H') {
        return [...houseCommitteeList, ...jointCommitteeList]
      }
      return [...senateCommitteeList, ...jointCommitteeList]
    },
    clientId() {
      return this.isSuperAdmin ? this.superAdminSelectedClient.id : this.userClientId
    },
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    isFederalLegislator() {
      return !!this.legislator.is_federal;
    },
    isFederalClient() {
      if(this.isSuperAdmin) {
        return this.superAdminSelectedClient.is_use_state === 0;
      }
      return this.userClient.is_use_state === 0;
    }
  },
  methods: {
    filterNotMainAddresses(addresses) {
      if(!Array.isArray(addresses)) {
        return [];
      }
      return addresses.filter(address => address.is_main === 0);
    },
    onChangeIsFederalHandler() {
      this.onSelectState()
    },
    async toggle(legislator) {
      this.tabs = null
      this.isModalOpen = true
      this.activeTabIndex = 0
      this.error = ''
      this.otherHobbies = []
      this.legislator = _.cloneDeep(legislator)
      this.legislator.occupation = this.legislator && this.legislator.occupation && this.legislator.occupation.replaceAll('<br>', '\n')
      this.selectCountryIdFromLegislatorStateId(this.legislator);
      this.legislator.leg_data = {
        leg_notes: legislator.leg_data && legislator.leg_data.leg_notes ? legislator.leg_data.leg_notes : legislator.leg_notes,
        vip_notes: this.legislator.vip_ao_note ? this.legislator.vip_ao_note : legislator.vip_note,
        leg_support: legislator.leg_data && legislator.leg_data.leg_support ? legislator.leg_data.leg_support : legislator.leg_support,
        leg_region: legislator.leg_data && legislator.leg_data.leg_region ? legislator.leg_data.leg_region : legislator.leg_region,
      }
      this.legislator.leg_data.cell_phone = legislator.leg_data && legislator.leg_data.cell_phone ? legislator.leg_data.cell_phone : legislator.cell_phone
      this.legislator.leg_data.custom_link = legislator.leg_data && legislator.leg_data.custom_link ? legislator.leg_data.custom_link : legislator.custom_link
      this.legislator.vip_note = this.legislator.vip_notes ? this.legislator.vip_notes : this.legislator.vip_note
      this.legislatorRegion = legislator.district.region && legislator.district.region.name ? legislator.district.region.name : ''
      const otherInterests = this.legislator.interests ? this.legislator.interests.filter(item => item.type === 'note_hobby') : []
      otherInterests.forEach(hobby => {
        this.otherHobbies[hobby.parent_id] = hobby.name
      })
      this.legislatorFavoriteTeams = this.legislator.interests ? this.legislator.interests.filter(item => item.type === 'favorite_team') : []
      await this.onSelectState()
      await this.getInterestsList()
      await this.getUniversity()
      await this.getStaff(this.staffPagination)
      await this.getStaffIssues()
      await this.getStaffRoles()

      const committees = {}
      legislator.legislator_committees.forEach((legislatorCommittees) => {
        committees[legislatorCommittees.committee_id] = legislatorCommittees.membership_states.map(membership => {
          return {
            id: membership.committee_membership_id,
            sub: membership.pivot.subcommittee
          }
        })
      })

      this.legislator.committees = committees
      this.getList().then(() => {
        this.getSelectedLegislatorsList()
      })
    },
    getUniversity() {
      const params = {
        'search': this.searchUniversities,
        'limit': 5,
      }
      this.getUniversities(params).then(response => {
        this.universities = response.data.data
      })
    },
    getStaff({ page, itemsPerPage }) {
      this.isBusy = true;
        const params = {
            legislator_id: this.legislator && this.legislator.id && this.legislator.id,
            client_id: this.clientId && this.clientId,
            page: page,
            limit: itemsPerPage,
        };
        this.getStaffByLegislator(params).then(response => {
            this.staffData.staffs = response.data && response.data.data ? response.data.data : response.data
            this.staffPagination.totalItems = response.data && response.data.total ? response.data.total : response.data.length
            this.isBusy = false;
        });
    },
    getStaffIssues() {
      const params = {
            client_id: this.clientId && this.clientId,
        };
      this.getStaffIssuesByClient(params).then(response => {
        this.issueList = ["All", ...response.data.data]
      })
    },
    getStaffRoles() {
      const params = {
            client_id: this.clientId && this.clientId,
        };
      this.getStaffRolesByClient(params).then(response => {
        this.roleList = ["All", ...response.data.data]
      })
    },
    getInterestsList() {
      this.isBusy = true
      this.getInterests(this.client.id).then(response => {
        this.hobbiesInterests = response.data
        this.isBusy = false
      })
        .catch((err) => {
          this.handleError(err)
          this.isBusy = false
        })
    },
    isEmptyInterest(interest) {
      const legislatorInterest = this.legislator.interests.findIndex(item => item.id === interest.id)
      return legislatorInterest >= 0
    },
    editLegislatorInterests(event, value) {
      if (event) {
        this.legislator.interests.push(value)
      } else {
        this.removeInterest(value)
      }
    },
    removeInterest(value) {
      const index = this.legislator.interests.findIndex(object => object.id === value.id);
      if (index >= 0) {
        const childInterests = this.legislator.interests.filter(object => object.parent_id === value.id)
        childInterests.forEach(object => this.removeInterest(object))
        this.legislator.interests.splice(index, 1)
      }
      if (value.type === 'watching_sports') {
        this.legislatorFavoriteTeams = [];
      }
    },
    changeLegislatorFavoriteTeams(value) {
      this.legislatorFavoriteTeams = value
    },
    removeMemberFavoriteTeam(value) {
      const index = this.legislatorFavoriteTeams.findIndex(object => object.id === value.id);
      this.legislatorFavoriteTeams.splice(index, 1)
    },
    setUniversity(value) {
      this.legislator.universities = value
    },
    removeUniversityFromLegislator(value) {
      this.legislator.universities.splice(value, 1)
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    disableVip() {
      if(this.legislator.vip_start && this.legislator.vip) {

        let legSalutation = '';
        if (this.legislator.district.district[0] === 'H') {
          legSalutation = this.client.formal_house + ' ' +  this.legislator.fullname;
        } else if (this.legislator.district.district[0] === 'S') {
          legSalutation = this.client.formal_senate + ' ' +  this.legislator.fullname;
        } else {
          legSalutation = this.legislator.fullname
        }

        // without the  changing legislator.vip variable switch doesn't works
        this.legislator.vip = false
        setTimeout( () => {
          this.legislator.vip = true
        }, 0)

        swal({
          title: `Remove VIP? This will end the VIP status for ${legSalutation} on today's date.`,
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.legislator.vip = false
          }
        })
      } else {
        this.legislator.vip = !this.legislator.vip
      }
    },
    onSelectTab(tabIndex) {
      this.activeTabIndex = tabIndex
    },
    async onSelectState() {
      this.initModalLoading = true
      if (!this.legislator.state_id) return

      const query = {};

      // if legislator is federal, then we need to get federal districts
      query.is_federal = this.isFederalLegislator ? 1 : 0

      this.districtList = (await this.getStateUnelectedDistricts(this.legislator.state_id, query)).data
      if (this.legislator.district) {
        this.districtList = [this.legislator.district, ...this.districtList]
      }

      this.initModalLoading = false
    },
    onUploadOfficialImage() {
      const file = this.$refs.officialImageUploader.files[0]
      if (!file) return
      this.uploadToCloudinary(file)
        .json((res) => {
          this.legislator.official_image = res.secure_url
          this.legislator = {...this.legislator}
        })
        .then(res => {
          this.onCallToastr('success', 'Legislator official image has been changed!', 'Success!')
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong!', 'Error!')
        })
    },
    onUpdateLegislator() {
      if (!this.$refs.legislatorForm.validate()) {
        this.error = 'Please fill all required fields.'
        return
      }
      this.error = ''
      this.isBusy = true
      this.legislator.other_interests = []
      for (const [id, hobby] of Object.entries(this.otherHobbies)) {
        this.legislator.other_interests.push({
          "parent_id": id,
          "name": hobby
        })
      }
      this.legislator.leg_data.leg_support = this.legislator.leg_data && this.legislator.leg_data.leg_support ? this.legislator.leg_data.leg_support : this.legislator.leg_support
      this.legislator.universities = this.legislator.universities.map(university => university.id)
      this.legislator.interests = this.legislator.interests.filter(item => item.type !== 'favorite_team')
      this.legislator.interests = this.legislator.interests.concat(this.legislatorFavoriteTeams)
      this.legislator.connections = this.selectedLegislators.map(legislator => legislator.id)
      this.legislator.staff_data = this.staffData
      const payload = _.cloneDeep(this.legislator)
      payload.client_id = this.clientId

      if(!payload.vip) delete payload.vip_note
      if (payload.party === 'None') {
        payload.party = ''
      }

      if (payload.abdicated_at) {
        swal({
          title: 'Are you sure? This will remove any assigned KPs as well as remove this legislator from all assigned Committees.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.saveLegislatorData(payload)
          } else {
            this.isBusy = false
          }
        })
      } else if (this.modifiedStaff) {
        swal({
          title: 'Are you sure? This will discard the changes made for staff.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.saveLegislatorData(payload)
          } else {
            this.isBusy = false
          }
        })      
      } else {
        this.saveLegislatorData(payload)
      }
    },
    saveLegislatorData(payload) {
      this.updateLegislator(payload)
        .then(() => {
          this.isBusy = false
          this.onCallToastr('success', 'Legislator info has been updated.', 'Success!')
          this.staffData = {
            "staffs": [],
            "updatedStaffs": [],
            "newlyAddedStaffs": []
          }
          this.issueList= [],
          this.roleList = [],
          this.refresh()
          this.isModalOpen = false
        })
        .catch(() => {
          this.isBusy = false
          this.error = 'Something went wrong!'
        })
    },
    checkAndSortArray(array, name) {
      if (array.length) {
        array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
        array.push({ divider: true })
        array.unshift({ header: name })
      }
      return array
    },
    getList() {
      let clientId = this.isSuperAdmin ? this.client.id : this.userClientId

      let param = {
        order: 'lastname',
      }
      return this.getClientLegislators(clientId, param).then((response) => {
        let legislators = response.data.data ? response.data.data : response.data
        this.legislatorList = legislators.filter(legislator => legislator.id !== this.legislator.id)
      })
    },
    getSelectedLegislatorsList() {
      let clientId = this.isSuperAdmin ? this.client.id : this.userClientId
      this.getConnections(clientId, this.legislator.id).then((response) => {
        this.selectedLegislators = response.data.data ? response.data.data : response.data
        this.legislator.connections = this.selectedLegislators
      })
    },
    removeLegislator(index, array, item) {
      swal({
        title: `Remove ${ item.fullname } from connections?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Remove',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          array.splice(index, 1)
        }
      })
    },
    filterLegislatorsList() {
        let houseLegislatorList = this.legislatorList.filter(item => item.district.district.includes('H'))
        let senateLegislatorList = this.legislatorList.filter(item => item.district.district.includes('S'))
        let data = [
          ...this.checkAndSortArray(houseLegislatorList, 'House'),
          ...this.checkAndSortArray(senateLegislatorList, 'Senate'),
        ]
        return data ? data : [];
    },
    filterIssuesAndRoles(staff) {
      return {
        ...staff,
        issues: JSON.parse(JSON.stringify(this.removeAllOccurrencesOfAll(staff.issues))),
        roles: JSON.parse(JSON.stringify(this.removeAllOccurrencesOfAll(staff.roles))),
      };
    },
    removeAllOccurrencesOfAll(array) {
      return array.filter(item => !(typeof item === 'string' && item === 'All'));
    },
    handleAddStaff(newStaff) {
      const filteredNewStaff = this.filterIssuesAndRoles(newStaff);
      filteredNewStaff.client_id = this.client && this.client.id
      this.staffData.staffs.push(filteredNewStaff);
      this.staffData.newlyAddedStaffs.push(filteredNewStaff);
    },

    handleUpdateStaff({ data }) {
      const filteredUpdatedStaff = this.filterIssuesAndRoles(data);
      filteredUpdatedStaff.client_id = this.client && this.client.id;
      const originalIndex = this.staffData.staffs.findIndex(
        (staff) => staff.id === filteredUpdatedStaff.id
      );

      if (originalIndex !== -1) {
        this.$set(this.staffData.staffs, originalIndex, filteredUpdatedStaff);

        const updatedStaff = this.staffData.staffs[originalIndex];

        if (!updatedStaff.id) {
          this.handleAddStaff(updatedStaff);
          return;
        }

        if (!this.staffData.updatedStaffs.some((staff) => staff.id === updatedStaff.id)) {
          this.staffData.updatedStaffs.push(updatedStaff);
        }
      }
    },

    selectCountryIdFromLegislatorStateId(legislator){
      if(!legislator.state_id) return;

      const state = this.stateList.find(state => state.id === legislator.state_id);
      if(state){
        this.countryId = state.state_of;
      }
    }
  },
  watch: {
    searchUniversities: function () {
      clearTimeout(this.timer)
      this.timer =  setTimeout(() => {
        this.getUniversity()
      }, 500)
    },
    selectedLegislators(newValue, oldValue) {
      if(newValue.length > 10) {
        this.selectedLegislators = newValue.slice(0, 10);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  background-color: white;
}

.v-dialog--fullscreen .v-toolbar__title {
  padding-left: 0 !important;
}
.v-tabs-items.full-height-tab  .v-window-item {
   height: 60vh;
   overflow-y: auto;
 }

.v-window-item--active {
  padding-top: 0 !important;
}
</style>
